import { MenuBlockWrapper, MenuBlockTitle } from "../../../common"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getHingesSide, getSelectedBg, getTooltips, selectBg, selectDoorHoleSizes, setHingesSide } from "../../../../features/widgetState/widgetSlice";
import styles from './backgrounds.module.scss'
import { useTranslation } from "react-i18next";

function Backgrounds() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedDoorHoleSizes = useAppSelector(selectDoorHoleSizes);
    const tooltips = useAppSelector(getTooltips);
    const hingesSide = useAppSelector(getHingesSide);
    const selectedBg = useAppSelector(getSelectedBg);
    const handleRedClick = (bgAlias: string) => {
        if (bgAlias == selectedBg?.alias) { return }

        dispatch(selectBg(bgAlias));
    };
    const hingesClick = (side: string) => {
        if (hingesSide === side) { return }
        dispatch(setHingesSide(side));
    }
    return (<MenuBlockWrapper>
        <MenuBlockTitle tooltipTitle={tooltips['doorOptions']}>{t(`doorHoleSize.title`)}</MenuBlockTitle>
        <div className={styles.itemsWrapper}>
            <div
                className={`${styles.hinges} ${hingesSide === "right" ? styles.selected : ''}`}
                onClick={() => hingesClick("right")} >{t(`doorOptions.hingesLeft`)}</div>
            <div
                className={`${styles.hinges} ${hingesSide === "left" ? styles.selected : ''}`}
                onClick={() => hingesClick("left")} >{t(`doorOptions.hingesRight`)}</div>
        </div>
        <div className={styles.itemsWrapper}>
            {selectedDoorHoleSizes.map((sizeKey: string) => {
                return <div className={`${selectedBg?.alias === sizeKey ? styles.selected : ''}`}
                    onClick={() => handleRedClick(sizeKey)}
                    key={sizeKey}>{t(`doorHoleSize.${sizeKey}`)}</div>
            })}
        </div>
    </MenuBlockWrapper>)
}

export default Backgrounds;