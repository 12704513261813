interface BgItem {
  color: string;
  style: string;
}
export enum FacadeType {
  plaster = 'plaster',
  horizontalWood = 'horizontalWood',
  verticalWood = 'verticalWood',
  bricks = 'bricks',
}

export enum SceneType {
  'classic' = 'classic',
  'modern' = 'modern',
  '60s' = '60s',
}
export enum DoorSizes {
  's921' = '9x20',
  's922' = '9x21',
  's1021' = '10x20',
  's1022' = '10x21',
}

export enum ScenesType {
  modern = 'sc01',
  klassisk = 'sc02',
  '60Tal' = 'sc02',
}

export enum DoorHoleSize {
  onlyDoor = 'do',
  door30side = 'ds30',
  door40side = 'ds40',
  door30sideTop = 'ds30t',
  door40sideTop = 'ds40t',
  doorTop = 'dt',
}

export interface SideglasItem {
  color: string;
  topglass: {
    tp90: string;
    tp120: string;
    tp130: string;
  };
  sideglass: {
    sd30: string;
    sd40: string;
  };
}
export interface SideglasList {
  [key: string]: SideglasItem;
}

export interface BgGroup {
  scene: {
    type: string;
  };
  facadeType: FacadeType;
  color: ColorItem;
  bg: {
    [key: string]: any;
  };
}

export interface ColorItem {
  alias: string;
  code: string;
  label?: string;
}

export const BgColors = {
  white: {
    alias: 'white',
    code: '#ffffff',
  },
  lightgrey: {
    alias: 'lightgrey',
    code: '#f1f2f0',
  },
  darkgrey: {
    alias: 'darkgrey',
    code: '#c2c0bd',
  },
  beige: {
    alias: 'beige',
    code: '#cdb386',
  },
  yellow: {
    alias: 'yellow',
    code: '#dba131',
  },
  red: {
    alias: 'red',
    code: '#89252f',
  },
  green: {
    alias: 'green',
    code: '#6c7644',
  },
  brown: {
    alias: 'brown',
    code: '#936945',
  },
  black: {
    alias: 'black',
    code: '#000',
  },
  blue: {
    alias: 'blue',
    code: '#33434a',
  },
};

export interface BgImageItem {
  alias: string;
  url: string;
}
export interface DoorItem {
  color: string;
  secondColor?: string;
  model?: string;
  label?: string;
  imgUrl: string;
}

export const DoorColors = {
  whiteYellow: {
    alias: 'whiteYellow',
    code: '#f3f1eb',
    label: 'Vit (NCS S 0502-Y)',
  },
  white: {
    alias: 'white',
    code: '#f3f3f4',
    label: 'Klarvit (NCS S 0500-N)',
  },
  lightgrey: {
    alias: 'lightgrey',
    code: '#9f9d97',
    label: 'Ljusgrå (NCS S 3502-Y)',
  },
  darkgrey: {
    alias: 'darkgrey',
    code: '#3b4346',
    label: 'Grå (NCS S 6502-B)',
  },
  green: {
    alias: 'green',
    code: '#365132',
    label: 'Grön (NCS S 5020-G30Y)',
  },
  blue: {
    alias: 'blue',
    code: '#1d3a58',
    label: 'BLÅ (NCS S 5030-R90B)',
  },
  red: {
    alias: 'red',
    code: '#540e12',
    label: 'Röd (NCS S 5040-R)',
  },
  black: {
    alias: 'black',
    code: '#1b2124',
    label: 'Svart (NCS S 9000-N)',
  },
};
export interface DoorModel {
  model: string;
  label: string;
  isGlass: boolean;
  iconUrl: string;
  defaults: {
    colorAlias: string;
    doorSize: string;
  };
  inside?: {
    default: string;
    items: Array<{ [key: string]: boolean }>;
  };
  glassOptions: {
    default: string;
    items: Array<{ [key: string]: boolean }>;
  };
  metalPlate: {
    hasMetalPlate: boolean;
    sizes: {
      items: Array<{ [key: string]: boolean }>;
    };
    types: {
      default: string;
      items: Array<{ [key: string]: boolean }>;
    };
  };
  hinges: {
    sizes: {
      default: string;
      items: Array<{ [key: string]: boolean }>;
    };
    types: {
      default: string;
      items: Array<{ [key: string]: boolean }>;
    };
  };
  items: Array<DoorItem>;
}

export interface BgGlassItem {
  img: string;
  iconUrl: string;
  alias: string;
  label: string;
}
export const BgGlassTypes = {
  clear: 'clear',
  costwood: 'costwood',
  frosted: 'frosted',
  cathedral: 'cathedral',
  smoked: 'smoked',
};

export interface DoorHandle extends BgGlassItem { }

export interface DoorHinges extends BgGlassItem {
  count: number;
}

export const DoorHandleTypes = {
  glossStandard: 'slossStandard',
};

export interface MetalPlateItem {
  img: string;
  size: number;
  alias: string;
}

export const extraMetalBlack = ['molly_glas', 'minnah_glas', "fanny_glas", "ronja_glas", "louise_glas", 'margareta_glas'];

export const extraMetalBlackColors = {
  black: {
    alias: 'black',
    code: '#251814',
    label: 'Svart glasram',
  },
  metal: {
    alias: 'metal',
    code: '#6f7172',
    label: 'Silvrig glasram',
  }
}


export const louiseGlasColors = {
  white: {
    alias: 'white',
    code: '#f9f9f9',
    label: 'vit (NCS S 0502-Y)',
  },
  grey: {
    alias: 'grey',
    code: '#3b4346',
    label: 'Grå (NCS S 6502-B)',
  },
  black: {
    alias: 'black',
    code: '#251814',
    label: 'Svart (NCS S 9000-N)',
  }
}

export const extraGlassColors = {
  transparent: {
    alias: 'transparent',
    code: '#f9f9f9',
    label: 'Transparent',
  },
  lightgrey: {
    alias: 'lightgrey',
    code: '#d1d1d1',
    label: 'Ljusgrå',
  },
  green: {
    alias: 'green',
    code: '#6cc34e',
    label: 'Grön',
  },
  blue: {
    alias: 'blue',
    code: '#2743a9',
    label: 'BLÅ',
  },
  red: {
    alias: 'red',
    code: '#c43030',
    label: 'Röd',
  }
}