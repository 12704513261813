import { SceneType, BgGlassTypes } from '../../types';

export const sideglassList = {
  white: {
    color: 'white',
    topglass: {
      tp90: './images/sideglasses/tp90Wh-min.png',
      tp120: './images/sideglasses/tp120Wh-min.png',
      tp130: './images/sideglasses/tp130Wh-min.png',
    },
    sideglass: {
      sd30: './images/sideglasses/sd30Wh-min.png',
      sd40: './images/sideglasses/sd40Wh-min.png',
    },
  },
  lightgrey: {
    color: 'lightgrLg',
    topglass: {
      tp90: './images/sideglasses/tp90Lg-min.png',
      tp120: './images/sideglasses/tp120Lg-min.png',
      tp130: './images/sideglasses/tp130Lg-min.png',
    },
    sideglass: {
      sd30: './images/sideglasses/sd30Lg-min.png',
      sd40: './images/sideglasses/sd40Lg-min.png',
    },
  },
  darkgrey: {
    color: 'darkgrey',
    topglass: {
      tp90: './images/sideglasses/tp90Dg-min.png',
      tp120: './images/sideglasses/tp120Dg-min.png',
      tp130: './images/sideglasses/tp130Dg-min.png',
    },
    sideglass: {
      sd30: './images/sideglasses/sd30Dg-min.png',
      sd40: './images/sideglasses/sd40Dg-min.png',
    },
  },
  whiteYellow: {
    color: 'whiteYellow',
    topglass: {
      tp90: './images/sideglasses/tp90WhYl-min.png',
      tp120: './images/sideglasses/tp120WhYl-min.png',
      tp130: './images/sideglasses/tp130WhYl-min.png',
    },
    sideglass: {
      sd30: './images/sideglasses/sd30WhYl-min.png',
      sd40: './images/sideglasses/sd40WhYl-min.png',
    },
  },
  green: {
    color: 'green',
    topglass: {
      tp90: './images/sideglasses/tp90Gr-min.png',
      tp120: './images/sideglasses/tp120Gr-min.png',
      tp130: './images/sideglasses/tp130Gr-min.png',
    },
    sideglass: {
      sd30: './images/sideglasses/sd30Gr-min.png',
      sd40: './images/sideglasses/sd40Gr-min.png',
    },
  },
  blue: {
    color: 'blue',
    topglass: {
      tp90: './images/sideglasses/tp90Bl-min.png',
      tp120: './images/sideglasses/tp120Bl-min.png',
      tp130: './images/sideglasses/tp130Bl-min.png',
    },
    sideglass: {
      sd30: './images/sideglasses/sd30Bl-min.png',
      sd40: './images/sideglasses/sd40Bl-min.png',
    },
  },
  red: {
    color: 'red',
    topglass: {
      tp90: './images/sideglasses/tp90R-min.png',
      tp120: './images/sideglasses/tp120R-min.png',
      tp130: './images/sideglasses/tp130R-min.png',
    },
    sideglass: {
      sd30: './images/sideglasses/sd30R-min.png',
      sd40: './images/sideglasses/sd40R-min.png',
    },
  },
  black: {
    color: 'black',
    topglass: {
      tp90: './images/sideglasses/tp90Blk-min.png',
      tp120: './images/sideglasses/tp120Blk-min.png',
      tp130: './images/sideglasses/tp130Blk-min.png',
    },
    sideglass: {
      sd30: './images/sideglasses/sd30Blk-min.png',
      sd40: './images/sideglasses/sd40Blk-min.png',
    },
  },
};

export const bgGlass = [
  {
    img: './images/glass/glCl-min.png',
    iconUrl: './images/glass/men-glCl.png',
    alias: BgGlassTypes.clear,
    label: BgGlassTypes.clear,
  },
  {
    img: './images/glass/glCo-min.png',
    iconUrl: './images/glass/men-glCo.png',
    alias: BgGlassTypes.costwood,
    label: BgGlassTypes.costwood,
  },
  {
    img: './images/glass/glFr-min.png',
    iconUrl: './images/glass/men-glFr.png',
    alias: BgGlassTypes.frosted,
    label: BgGlassTypes.frosted,
  },
  {
    img: './images/glass/glKa-min.png',
    iconUrl: './images/glass/men-glKa.png',
    alias: BgGlassTypes.cathedral,
    label: BgGlassTypes.cathedral,
  },
  {
    img: './images/glass/glRo-min.png',
    iconUrl: './images/glass/men-glRo.png',
    alias: BgGlassTypes.smoked,
    label: BgGlassTypes.smoked,
  },
];

export const scenesFigures = {
  [SceneType.modern]: './images/scenesFigures/fg01.png',
  [SceneType['60s']]: './images/scenesFigures/fg03.png',
  [SceneType.classic]: './images/bg/sc04/fg04.png',
};

export const doorHandles = [
  {
    img: './images/handles/dhAssaEp27Br.png',
    iconUrl: './images/handles/men-dhAssaEp27Br.png',
    alias: 'dhAssaEp27Br',
    label: 'dhAssaEp27Br',
  },
  {
    img: './images/handles/dhAssaEp27Sta.png',
    iconUrl: './images/handles/men-dhAssaEp27Sta.png',
    alias: 'dhAssaEp27Sta',
    label: 'dhAssaEp27Sta',
  },
  {
    img: './images/handles/dhAssaEp37Br.png',
    iconUrl: './images/handles/men-dhAssaEp37Br.png',
    alias: 'dhAssaEp37Br',
    label: 'dhAssaEp37Br',
  },
  {
    img: './images/handles/dhAssaEp37Sta.png',
    iconUrl: './images/handles/men-dhAssaEp37Sta.png',
    alias: 'dhAssaEp37Sta',
    label: 'dhAssaEp37Sta',
  },
  {
    img: './images/handles/dhHopStockAlu.png',
    iconUrl: './images/handles/men-dhHopStockAlu.png',
    alias: 'dhHopStockAlu',
    label: 'dhHopStockAlu',
  },
  {
    img: './images/handles/dhHopStockSta.png',
    iconUrl: './images/handles/men-dhHopStockSta.png',
    alias: 'dhHopStockSta',
    label: 'dhHopStockSta',
  },
  {
    img: './images/handles/dhHopStockBlack.png',
    iconUrl: './images/handles/men-dhHopStockBlack.png',
    alias: 'dhHopStockBlack',
    label: 'dhHopStockBlack',
  },
  {
    img: './images/handles/dhDenvBl.png',
    iconUrl: './images/handles/men-dhDenvBl.png',
    alias: 'dhDenvBl',
    label: 'dhDenvBl',
  },
];

export const doorHinges = [
  {
    count: 3,
    img: './images/hinges/DHindg3GlSt-min.png',
    iconUrl: './images/hinges/menu/gloss_standard.png',
    alias: 'glossStandard3',
    label: 'glossStandard',
  },
  {
    count: 4,
    img: './images/hinges/Hindg4GlSt-min.png',
    iconUrl: './images/hinges/menu/gloss_standard.png',
    alias: 'glossStandard4',
    label: 'glossStandard',
  },
  {
    count: 3,
    img: './images/hinges/dHindg3MeSt-min.png',
    iconUrl: './images/hinges/menu/stainless_standard.png',
    alias: 'stainlessStandard3',
    label: 'stainlessStandard',
  },
  {
    count: 4,
    img: './images/hinges/dHindg4MeSt-min.png',
    iconUrl: './images/hinges/menu/stainless_standard.png',
    alias: 'stainlessStandard4',
    label: 'stainlessStandard',
  },
  {
    count: 3,
    img: './images/hinges/dHindg3BlA-min.png',
    iconUrl: './images/hinges/menu/black_tradition.png',
    alias: 'blackAllmoge3',
    label: 'blackAllmoge',
  },
  {
    count: 4,
    img: './images/hinges/dHindg4BlA-min.png',
    iconUrl: './images/hinges/menu/black_tradition.png',
    alias: 'blackAllmoge4',
    label: 'blackAllmoge',
  },
  {
    count: 3,
    img: './images/hinges/dHindg3BlSt-min.png',
    iconUrl: './images/hinges/menu/black_standard.png',
    alias: 'blackZink3',
    label: 'blackStandard',
  },
  {
    count: 4,
    img: './images/hinges/dHindg4BlSt-min.png',
    iconUrl: './images/hinges/menu/black_standard.png',
    alias: 'blackZink4',
    label: 'blackStandard',
  },
  {
    count: 3,
    img: './images/hinges/dHindg3WhSt-min.png',
    iconUrl: './images/hinges/menu/white_standard.png',
    alias: 'white3',
    label: 'whiteStandard',
  },
  {
    count: 4,
    img: './images/hinges/dHindg4WhSt-min.png',
    iconUrl: './images/hinges/menu/white_standard.png',
    alias: 'white4',
    label: 'whiteStandard',
  },
  {
    count: 3,
    img: '',
    iconUrl: './images/hinges/menu/hidden.png',
    alias: 'dolda3',
    label: 'dolda',
  },
  {
    count: 4,
    img: '',
    iconUrl: './images/hinges/menu/hidden.png',
    alias: 'dolda4',
    label: 'dolda',
  },
];

export const metalPlates = [
  {
    size: 100,
    img: './images/mp/mp100Al.png',
    alias: 'alu',
  },
  {
    size: 100,
    img: './images/mp/mp100Bl.png',
    alias: 'black',
  },
  {
    size: 100,
    img: './images/mp/mp100St.png',
    alias: 'stainless',
  },
  {
    size: 190,
    img: './images/mp/mp190Al.png',
    alias: 'alu',
  },
  {
    size: 190,
    img: './images/mp/mp190Bl.png',
    alias: 'black',
  },
  {
    size: 190,
    img: './images/mp/mp190St.png',
    alias: 'stainless',
  },
];
