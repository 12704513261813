import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import './App.scss';
import Menu from './components/Menu';
import WidgetBodyWrapper from './components/WidgetBody';
import { useAppDispatch, useAppSelector } from './app/hooks';
import {
  getFacadeTypes,
  // selectFacadeType,
  getWidgetData,
  // selectSceneType,
  getAvailableScenes,
  getIsLoading,
  mergeDoorsWidgetData,
  setIsLoading,
  selectFacadeSceneType,
  getSelectedState,
} from './features/widgetState/widgetSlice';
import Loader from './components/common/Loader';
import { fetchInitialData } from './features/widgetState/fetchDefaultData';
import RotateMobile from './components/common/RotateMobile';
import FullscreenButton from './components/common/FullScreenButton';
import Modal from './components/Modal/Modal';

function App() {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const isLoading = useAppSelector(getIsLoading);
  const widgetData = useAppSelector(getWidgetData);
  const selectedState = useAppSelector(getSelectedState);
  const facadeTypes = useAppSelector(getFacadeTypes);
  const availableScenes = useAppSelector(getAvailableScenes);
  const [sholdChangeOrientation, setChaneOrientation] = useState(false);


  function getOrientationAngle() {
    if (window.screen.orientation && window.screen.orientation.angle !== undefined) {
      return window.screen.orientation.angle;
    } else if (window.orientation !== undefined) {
      return window.orientation;
    } else {
      // No support for screen orientation
      console.log("No support for screen orientation");
      return 90;
    }
  }
  useEffect(() => {
    dispatch(selectFacadeSceneType({ scene: availableScenes[0], facadeType: facadeTypes[0] }));
  }, [widgetData]);
  useEffect(() => {
    const {
      scene,
      facade,
      hinges,
      bg,
      doorModel,
      doorItem,
      doorColorInside,
      doorHinges,
      isGlass,
      glassItem,
      doorHandle,
      insideItem,
      metalPlate
    } = selectedState;
    const searchParams = new URLSearchParams();
    // scene
    searchParams.set('sc', scene.sceneType);
    // facade type
    searchParams.set('ft', `${facade?.type}`);
    // facade color
    searchParams.set('fc', `${facade?.color}`);
    // hinges side
    searchParams.set('hgs', `${hinges}`);
    // bg size alias
    searchParams.set('bga', `${bg.alias}`);
    searchParams.set('isgl', `${isGlass ? 1 : 0}`);
    if (isGlass) {
      searchParams.set('gl', `${glassItem.alias}`);
    }
    searchParams.set('md', `${doorModel?.model}`);
    searchParams.set('mdc', `${doorItem?.color}`);
    searchParams.set('ins', `${doorColorInside.alias}`);
    searchParams.set('hg', `${doorHinges.item?.alias}`);
    searchParams.set('hn', `${doorHandle.alias}`);
    searchParams.set('insi', `${insideItem}`);
    if (metalPlate.selected?.alias) {
      searchParams.set('mp', `${metalPlate.selected?.alias}`);
      searchParams.set('mps', `${metalPlate.selected?.size}`);
    }
    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
  }, [selectedState]);
  useEffect(() => {
    fetchInitialData()
      .then((data) => {
        dispatch(mergeDoorsWidgetData({ doorModels: data.doorModels, tooltips: data.tooltips }));
        dispatch(setIsLoading(false));
        // const hasShownModal = sessionStorage.getItem('hasShownModal');
        // if (!hasShownModal) {
        //   setIsOpen(true);
        //   sessionStorage.setItem('hasShownModal', 'true');
        // }
      })
      .catch((error) => {
        // store.dispatch(setError(error.message));
      });
    if (isMobile && getOrientationAngle() === 0 && window.innerWidth < window.innerHeight) {
      setChaneOrientation(true);
    }
    function handleOrientationChange() {
      if (isMobile && getOrientationAngle() === 0 && window.innerWidth < window.innerHeight) {
        setChaneOrientation(true);
      } else if (isMobile) {
        setChaneOrientation(false);
      }
    }

    window.addEventListener("resize", handleOrientationChange);
    // Cleanup function to be run when the component unmounts
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  return (
    <div className={`App${isMobile ? " mobileView" : ""}`}>
      <div className="main--wrapper">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {sholdChangeOrientation ? <RotateMobile /> : null}
            {/* <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} /> */}
            {/* {isMobile ? <FullscreenButton /> : null} */}
            <div className="top-menu--wrapper">
              <div className="logo--wrapper">
                <img src="./Diplomat_Logo_Positiv_RGB.png" alt="diplomat" />
              </div>
            </div>
            <div className="main-body--wrapper">
              <div className="configurator--svg-wrapper">
                <WidgetBodyWrapper />
              </div>
              <div className="menu--wrapper">
                <Menu />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
