import { Image, StyleSheet, View, Text, Page, Document } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        padding: "30px 30px 0 30px",
    },
    greyView: {
        padding: "30px 30px 0 30px",
    },
    subHeader: {
        fontSize: 12,
        textAlign: 'left',
        marginBottom: 10,
    },
    header: {
        fontSize: 18,
        textAlign: 'left',
        marginBottom: 30,
    },
    image: {
        position: 'absolute',
        top: 0, // Adjust these values according to your requirements
        right: 0, // Adjust these values according to your requirements
        width: 94,
        height: 38,
    },
    text: {
        fontSize: 11,
        marginBottom: 10,
    },
    twoColumn: {
        flexDirection: 'row',
        marginBottom: 15,
    },
    block: {
        width: '50%',
    },
    whiteBlock: {
        backgroundColor: "#f2f3f4",
        padding: "20px 30px",
        borderRadius: "5px"
    },
    hRed: {
        fontSize: "12px",
        color: "#da1a30",
        fontWeight: 900,
        paddingBottom: "10px"
    },
    textRed: {
        fontSize: "10px",
        color: "#da1a30",
    },
    textLeftBlockd: {
        padding: "0 20px 20px 0"
    },
    textBold: {
        fontSize: "10px",
        fontWeight: 900,
        paddingBottom: "10px"
    },
    textH2: {
        fontSize: "12px",
        fontWeight: 900,
        paddingBottom: "10px"
    },
    dataBlock: {
        marginBottom: 20,
        width: '55%',
    },
    dataBlockContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
        marginRight: 15
    },
    blockDoor: {
        marginLeft: 20,
        marginTop: 30,
        width: '45%'
    },
    doorImage: {
        width: '90%',
        height: 'auto',
    },
    doorImageLeft: {
        width: '90%',
        height: 'auto',
        transform: "scaleX(-1)"
    },
});

interface Props {
    metalplate?: string;
    doorHinges?: string;
    doorHandle?: string;
    hingesSide?: string;
    doorColorInside?: string;
    doorColorOut?: string;
    glass?: string;
    doorSize?: string;
    doorHoleSize?: string;
    insideItem: string;
    doorModel?: string;
    threshold?: string;
    extraTitle?: string;
    extraValue?: string;
    doorImage?: string;
    hignesSide?: string;
}

const PdfDocument: React.FC<Props> = ({
    extraTitle,
    extraValue,
    threshold,
    metalplate,
    insideItem,
    doorHinges,
    doorHandle,
    doorHoleSize,
    hingesSide,
    doorColorInside,
    doorColorOut,
    doorModel,
    glass,
    doorImage,
    doorSize,
    hignesSide
}) => {
    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.greyView}>
                    <Image style={styles.image} src="./logo.jpg" />
                    <View style={styles.twoColumn}>
                        <View style={styles.dataBlock}>
                            <Text style={styles.subHeader}>YTTERDÖRR</Text>
                            <Text style={styles.header}>Diplomat {doorModel}</Text>
                            <View style={styles.dataBlockContainer}>
                                <Text style={styles.text}>Storlek</Text>
                                <Text style={styles.text}>{doorSize}</Text>
                            </View>
                            {glass ?
                                <View style={styles.dataBlockContainer}>
                                    <Text style={styles.text}>Glas</Text>
                                    <Text style={styles.text}>{glass}</Text>
                                </View> : null}
                            <View style={styles.dataBlockContainer}>
                                <Text style={styles.text}>Färg dörrblad</Text>
                                <Text style={styles.text}>{doorColorOut}</Text>
                            </View>
                            <View style={styles.dataBlockContainer}>
                                <Text style={styles.text}>Färg dörrblad insida</Text>
                                <Text style={styles.text}>{doorColorInside}</Text>
                            </View>
                            {extraTitle && extraValue ? <View style={styles.dataBlockContainer}>
                                <Text style={styles.text}>{extraTitle}</Text>
                                <Text style={styles.text}>{extraValue}</Text>
                            </View> : null}
                            <View style={styles.dataBlockContainer}>
                                <Text style={styles.text}>Dörralternativ</Text>
                                <Text style={styles.text}>{hingesSide}</Text>
                            </View>
                            <View style={styles.dataBlockContainer}>
                                <Text style={styles.text}>Dörralternativ</Text>
                                <Text style={styles.text}>{doorHoleSize}</Text>
                            </View>
                            <View style={styles.dataBlockContainer}>
                                <Text style={styles.text}>Trycke</Text>
                                <Text style={styles.text}>{doorHandle}</Text>
                            </View>
                            <View style={styles.dataBlockContainer}>
                                <Text style={styles.text}>Gångjärn</Text>
                                <Text style={styles.text}>{doorHinges}</Text>
                            </View>
                            <View style={styles.dataBlockContainer}>
                                <Text style={styles.text}>Tillval insida</Text>
                                <Text style={styles.text}>{insideItem}</Text>
                            </View>
                            <View style={styles.dataBlockContainer}>
                                <Text style={styles.text}>Sparkplåt</Text>
                                <Text style={styles.text}>{metalplate}</Text>
                            </View>
                            <View style={styles.dataBlockContainer}>
                                <Text style={styles.text}>Tröskel</Text>
                                <Text style={styles.text}>{threshold}</Text>
                            </View>
                        </View>
                        <View style={styles.blockDoor}>
                            <Image style={hignesSide == 'right' ? styles.doorImage : styles.doorImageLeft} src={doorImage || "./logo.jpg"} />
                        </View>
                    </View>
                    <View style={styles.twoColumn}>
                        <View style={styles.block}>
                            <View style={styles.textLeftBlockd}>
                                <Text style={styles.textH2}>Vilken snygg Diplomatdörr!</Text>
                                <Text style={styles.textBold}>Vill du att den ska bli verklig? Ta med dig denna specifikation till din närmaste återförsäljare som hjälper dig beställa.</Text>
                                <Text style={styles.textH2}>#diplomatdörrar</Text>
                                <Text style={styles.textBold}> Vi vill se din dörr i verkligheten! Tagga gärna
                                    #diplomatdörrar på instagram och/eller facebook
                                    och ta en bild när din dörr är på plats!</Text>
                                <Text style={styles.textH2}>Hittar du inte riktigt vad du sökte?</Text>
                                <Text style={styles.textBold}>Tveka inte att kontakta någon av våra återförsäljare för
                                    att diskutera ytterligare möjligheter och valmöjligheter.
                                    Vi erbjuder fler kulörer, mått, handtag och cylindrar.
                                    En återförsäljare kan också hjälpa dig att hitta rätt
                                    bland våra pardörrar, förrådsdörrar och garageportar.</Text>
                                <Text style={styles.textH2}>Är du osäker på dina angivna mått?</Text>
                                <Text style={styles.textBold}>Kontrollera gärna igen och ta hjälp av vår mätguide</Text>
                                <Text style={styles.textRed}>Modulmått - Diplomat (diplomatdorrar.se)</Text>


                            </View>
                        </View>
                        <View style={styles.block}>
                            <View style={styles.whiteBlock}>
                                <Text style={styles.hRed}>DIPLOMAT DÖRRAR AB</Text>
                                <Text style={styles.textBold}>Diplomat Dörrar AB har en lång tradition inom dörrtillverkning och är en av de ledande dörrtillverkarna i Sverige. I småländska Bankeryd producerar vi årligen cirka 25 000 ytterdörrar.</Text>
                                <Text style={styles.textBold}>Våra dörrkunskaper finns inte bara i vårt eget hus, utan också ute hos våra återförsäljare. Hos dem får du hjälp med goda råd och svar på dina frågor.</Text>
                                <Text style={styles.textBold}>Ett antal av våra återförsäljare har även showroom där du i lugn och ro kan se och känna kvaliteten på våra dörrar.</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
};

export default PdfDocument;
