import { MenuBlockTitle, MenuBlockWrapper } from '../../../common';
import styles from './metalplate.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  getWidgetData,
  getSelectedMetalPlateItem,
  setMetalPlateItem,
  getSelectedDoorModel,
} from '../../../../features/widgetState/widgetSlice';
import { useEffect, useState } from 'react';

function MetalPlateItem() {
  const [showMenu, setShowMenu] = useState<boolean>(true);
  const [sizes, setSizes] = useState([190, 100]);
  const [mpTypes, setMpTypes] = useState(['stainless', 'black', 'alu']);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { metalPlates, tooltips } = useAppSelector(getWidgetData);
  const selectedMp = useAppSelector(getSelectedMetalPlateItem);
  const doorModel = useAppSelector(getSelectedDoorModel);
  useEffect(() => {
    if (doorModel) {
      const { metalPlate } = doorModel;
      if (!metalPlate.hasMetalPlate) {
        setShowMenu(false);
      }
      if (metalPlate?.sizes?.items) {
        if (!showMenu) {
          setShowMenu(true);
        }
        const sizes: Array<number> = []
        metalPlate.sizes.items.forEach((obj) => {
          Object.entries(obj).forEach(([key, value]) => {
            if (value) {
              sizes.push(Number(key));
            }
          });
        });
        setSizes(sizes);
      }
      if (metalPlate?.types?.items) {
        const types: Array<string> = [];
        // transform array {prop: true} to [prop, prop]
        metalPlate?.types?.items.forEach((obj) => {
          Object.entries(obj).forEach(([key, value]) => {
            if (value) {
              types.push(key);
            }
          });
        });
        setMpTypes(types);
      }
      if (metalPlate?.types?.default) {
        const preSelected = metalPlates.find(({ alias }) => {
          return metalPlate?.types?.default === alias;
        });
        if (preSelected) {
          dispatch(setMetalPlateItem(preSelected));
        }
      }
    }
  }, [doorModel]);
  function handleClick(item) {
    dispatch(setMetalPlateItem(item));
  }
  return (
    <>
      {showMenu ? (
        <MenuBlockWrapper>
          <MenuBlockTitle tooltipTitle={tooltips['metalPlate']}>{t(`metalPlate.title`)}</MenuBlockTitle>
          <div className={styles.unselectWrapper}>
            <div
              className={`${!selectedMp?.alias ? styles.selectedUn : ''}`}
              onClick={() => handleClick({ alias: undefined })}
            >
              {t(`metalPlate.unselect`)}
            </div>
          </div>
          <div className={styles.itemsWrapper}>
            {metalPlates
              // filter by not supported sizes
              .filter(({ size }) => sizes.includes(size))
              // filter not supported types
              .filter(({ alias }) => mpTypes.includes(alias))
              .map((item, index) => {
                return (
                  <div
                    onClick={() => handleClick(item)}
                    className={`${selectedMp?.alias === item.alias &&
                      selectedMp.size === item.size
                      ? styles.selected
                      : ''
                      }`}
                    key={index}
                  >
                    {t(`metalPlate.${item.alias}`)} {item.size}
                  </div>
                );
              })}
          </div>
        </MenuBlockWrapper>
      ) : null}
    </>
  );
}

export default MetalPlateItem;
