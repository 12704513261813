import { useTranslation } from "react-i18next";
import { MenuBlockTitle, MenuBlockWrapper } from "../../../common";
import styles from './threshol.module.scss'
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getSelectedThreshold, getTooltips, setSelectedThreshold } from "../../../../features/widgetState/widgetSlice";

function ThresholdMenu() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const tooltips = useAppSelector(getTooltips);
    const threshold = useAppSelector(getSelectedThreshold)
    const handleClick = (selected) => {
        dispatch(setSelectedThreshold(selected));
    }
    return <MenuBlockWrapper>
        <MenuBlockTitle tooltipTitle={tooltips['threshold']}>{t(`threshold.title`)}</MenuBlockTitle>
        <div className={styles.itemsWrapper}>
            <div
                onClick={() => handleClick("25")}
                className={`${threshold === "25" ? styles.selected : ''}`}>{t(`threshold.25`)}</div>
            <div
                onClick={() => handleClick("35")}
                className={`${threshold === "35" ? styles.selected : ''}`}>{t(`threshold.35`)}</div>
        </div>
    </MenuBlockWrapper>
}
export default ThresholdMenu;