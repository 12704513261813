import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks"
import { getHingesSide, getSelectedBg, getSelectedDoorHandle, getSelectedDoorModel, getSelectedMetalBlackColor, getWidgetData } from "../../../../features/widgetState/widgetSlice"
import { ColorItem, DoorHoleSize } from "../../../../types";

interface DoorHandleProps {
    imgUrl?: string;
    leftHinges?: boolean;
}

function DoorHoleRight({ imgUrl, leftHinges }: DoorHandleProps) {
    return <>
        <defs>
            <pattern id="img-extras" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform={`${leftHinges ? "translate(-90,0) scale(-1,1)" : "translate(0,0)"}`}>
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="door" x="701.92" y="194.15" style={{ fill: 'url(#img-extras)' }} width="302.94" height="648.25" />
    </>
}

function DoorHoleLeft({ imgUrl }: DoorHandleProps) {
    return <>
        <defs>
            <pattern id="img-extras" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform="translate(30,0) scale(-1,1)">
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="img-extras-left" style={{ fill: 'url(#img-extras)' }} x="829.341" y="198.759" width="296.85" height="645.578" />
        <rect id="img-extras-left" style={{ fill: 'url(#img-extras)' }} x="824.89" y="194.15" width="302.94" height="648.25" />
    </>
}
function DoorHoleSd30Left({ imgUrl }: DoorHandleProps) {
    return <>
        <defs>
            <pattern id="img-extras" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform="translate(-2,0) scale(-1,1)">
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="img-extras-sd30-left" style={{ fill: 'url(#img-extras)' }} x="794.22" y="194.17" width="302.94" height="648.25" />
    </>
}
const ExtraBg = {
    molly_glas: './images/extra/molly_glas/ExtraOptionBlack-min.png',
    minnah_glas: './images/extra/minnah_glas/ExtraOptionBlack-min.png',
}

function ExtrasLayer() {
    const [extra, setExtraBg] = useState('');
    const selectedBg = useAppSelector(getSelectedBg);
    const hignesSide = useAppSelector(getHingesSide);
    const doorModel = useAppSelector(getSelectedDoorModel);
    const selectedMetalBlackColor = useAppSelector(getSelectedMetalBlackColor) as ColorItem;
    const { extras } = useAppSelector(getWidgetData);
    useEffect(() => {
        if (selectedMetalBlackColor.alias === "transparent") {
            setExtraBg('');
            return;
        }
        switch (doorModel?.model) {
            case "molly_glas":
                setExtraBg(extras['blackMetal']['molly_glas'][selectedMetalBlackColor.alias]);
                break;
            case "minnah_glas":
                setExtraBg(extras['blackMetal']['minnah_glas'][selectedMetalBlackColor.alias]);
                break;
            case "ronja_glas":
                setExtraBg(extras['blackMetal']['ronja_glas'][selectedMetalBlackColor.alias]);
                break;
            case "margareta_glas":
                setExtraBg(extras['blackMetal']['margareta_glas'][selectedMetalBlackColor.alias]);
                break;
            case "louise_glas":
                setExtraBg(extras['blackMetal']['louise_glas'][selectedMetalBlackColor.alias]);
                break;
            default:
                setExtraBg("");
        }
    }, [doorModel, selectedMetalBlackColor]);
    function DoorHandleChoser(alias) {
        switch (alias) {
            case DoorHoleSize.door40side:
            case DoorHoleSize.door40sideTop:
                return <>{hignesSide === "left" ? <DoorHoleLeft imgUrl={extra} /> : <DoorHoleRight imgUrl={extra} />}</>
            case DoorHoleSize.door30sideTop:
            case DoorHoleSize.door30side:
                return <>{hignesSide === "left" ? <DoorHoleSd30Left imgUrl={extra} /> : <DoorHoleRight imgUrl={extra} />}</>
            default:
                return <DoorHoleRight leftHinges={hignesSide === "left"} imgUrl={extra} />
        }
    }
    return <>{extra && DoorHandleChoser(selectedBg.alias)}</>
}

export default ExtrasLayer