import { DoorHoleSize } from '../../../../types';
import { useAppSelector } from '../../../../app/hooks';
import {
  getHingesSide,
  getSelectedBg,
  getSelectedMetalPlateItem,
} from '../../../../features/widgetState/widgetSlice';

function MetalPlateRight({ item }) {
  return (
    <>
      <defs>
        <pattern
          id="img-metalplate"
          patternUnits="userSpaceOnUse"
          width="1800"
          height="1010"
          patternTransform="translate(0,0)"
        >
          <image
            xlinkHref={`${item.img}`}
            x="0"
            y="0"
            width="1800"
            height="1010"
          />
        </pattern>
      </defs>
      {item.size === 100 ? (
        <rect
          id="mp_100"
          style={{ fill: 'url(#img-metalplate)' }}
          x="712.01"
          y="800.92"
          width="283.06"
          height="31.06"
        />
      ) : (
        <rect
          id="mp_190"
          style={{ fill: 'url(#img-metalplate)' }}
          x="712.01"
          y="773.25"
          width="283.06"
          height="58.73"
        />
      )}
    </>
  );
}
function MetalPlateLeftS30({ item }) {
  return (
    <>
      <defs>
        <pattern
          id="img-metalplate"
          patternUnits="userSpaceOnUse"
          width="1800"
          height="1010"
          patternTransform="translate(90,0)"
        >
          <image
            xlinkHref={`${item.img}`}
            x="0"
            y="0"
            width="1800"
            height="1010"
          />
        </pattern>
      </defs>
      {item.size === 100 ? (
        <rect
          id="mp_100"
          style={{ fill: 'url(#img-metalplate)' }}
          x="804.1"
          y="800.93"
          width="283.06"
          height="31.06"
        />
      ) : (
        <rect
          id="mp_190"
          style={{ fill: 'url(#img-metalplate)' }}
          x="804.1"
          y="773.27"
          width="283.06"
          height="58.73"
        />
      )}
    </>
  );
}
function MetalPlateLeftS40({ item }) {
  return (
    <>
      <defs>
        <pattern
          id="img-metalplate"
          patternUnits="userSpaceOnUse"
          width="1800"
          height="1010"
          patternTransform="translate(123,0)"
        >
          <image
            xlinkHref={`${item.img}`}
            x="0"
            y="0"
            width="1800"
            height="1010"
          />
        </pattern>
      </defs>
      {item.size === 100 ? (
        <rect
          id="mp_100"
          style={{ fill: 'url(#img-metalplate)' }}
          x="834.98"
          y="800.92"
          width="283.06"
          height="31.06"
        />
      ) : (
        <rect
          id="mp_190"
          style={{ fill: 'url(#img-metalplate)' }}
          x="834.98"
          y="773.25"
          width="283.06"
          height="58.73"
        />
      )}
    </>
  );
}

function MetalPlateLayer() {
  const selectedBg = useAppSelector(getSelectedBg);
  const selectedMp = useAppSelector(getSelectedMetalPlateItem);
  const hingesSide = useAppSelector(getHingesSide);
  const isLeft = hingesSide === 'left';
  function Layer({ bgAlias }) {
    switch (bgAlias) {
      case DoorHoleSize.door30side:
      case DoorHoleSize.door30sideTop:
        return (
          <>
            {isLeft ? (
              <MetalPlateLeftS30 item={selectedMp} />
            ) : (
              <MetalPlateRight item={selectedMp} />
            )}
          </>
        );
      case DoorHoleSize.door40side:
      case DoorHoleSize.door40sideTop:
        return (
          <>
            {isLeft ? (
              <MetalPlateLeftS40 item={selectedMp} />
            ) : (
              <MetalPlateRight item={selectedMp} />
            )}
          </>
        );
      default:
        return <MetalPlateRight item={selectedMp} />;
    }
  }

  return <>{selectedMp?.img ? <Layer bgAlias={selectedBg.alias} /> : null}</>;
}

export default MetalPlateLayer;
