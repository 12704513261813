import { BgColors, FacadeType, SceneType } from '../../types';

export const bgScene01 = [
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.white,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dovfWh-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dovfWh-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dovfWh-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dovfWh-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dovfWh-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dovfWh-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.lightgrey,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dovfLg-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dovfLg-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dovfLg-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dovfLg-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dovfLg-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dovfLg-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.darkgrey,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dovfDg-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dovfDg-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dovfDg-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dovfDg-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dovfDg-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dovfDg-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.beige,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dovfBe-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dovfBe-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dovfBe-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dovfBe-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dovfBe-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dovfBe-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.brown,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dovfBr-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dovfBr-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dovfBr-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dovfBr-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dovfBr-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dovfBr-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.yellow,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dovfYe-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dovfYe-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dovfYe-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dovfYe-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dovfYe-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dovfYe-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.red,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dovfRe-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dovfRe-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dovfRe-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dovfRe-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dovfRe-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dovfRe-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.green,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dovfGr-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dovfGr-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dovfGr-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dovfGr-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dovfGr-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dovfGr-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.black,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dovfBl-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dovfBl-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dovfBl-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dovfBl-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dovfBl-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dovfBl-ds40t.png',
      },
    },
  },

  // Horizontal
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.white,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dohfWh-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dohfWh-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dohfWh-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dohfWh-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dohfWh-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dohfWh-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.lightgrey,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dohfLg-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dohfLg-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dohfLg-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dohfLg-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dohfLg-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dohfLg-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.darkgrey,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dohfDg-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dohfDg-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dohfDg-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dohfDg-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dohfDg-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dohfDg-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.beige,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dohfBe-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dohfBe-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dohfBe-ds30.png',
      },
      // "ds30t": {
      //     alias: 'ds30t',
      //     url: './images/bg/sc01/sc01dohfBe-ds30t.png'
      // },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dohfBe-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dohfBe-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.yellow,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dohfYe-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dohfYe-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dohfYe-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dohfYe-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dohfYe-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dohfYe-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.red,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dohfRe-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dohfRe-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dohfRe-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dohfRe-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dohfRe-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dohfRe-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.green,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dohfGr-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dohfGr-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dohfGr-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dohfGr-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dohfGr-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dohfGr-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.brown,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dohfBr-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dohfBr-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dohfBr-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dohfBr-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dohfBr-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dohfBr-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.modern,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.black,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc01/sc01dohfBl-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc01/sc01dohfBl-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc01/sc01dohfBl-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc01/sc01dohfBl-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc01/sc01dohfBl-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc01/sc01dohfBl-ds40t.png',
      },
    },
  },
];
