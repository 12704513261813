import { ReactComponent as AnimatedCircleIcon } from './animated-icon.svg';

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        minHeight: '400px',
        flexWrap: 'wrap',
      }}
    >
      <img alt="loader" src="./images/spinner-1.gif" />
    </div>
  );
};

export default Loader;
