import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getSelectedDoorHandle, getSelectedDoorHinges, getSelectedDoorModel, getWidgetData, setDoorHingesItem } from "../../../../features/widgetState/widgetSlice";
import IconItemsSelectPalette from "../../../common/IconItemsSelectPalette";
import { useEffect, useState } from "react";



function HingesItemsSelector() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { doorHinges, tooltips } = useAppSelector(getWidgetData);
    const selectedHandle = useAppSelector(getSelectedDoorHandle);
    const selectedHinges = useAppSelector(getSelectedDoorHinges);
    const selectedDoorModel = useAppSelector(getSelectedDoorModel);
    const [avHinges, setAvHinges] = useState(["glossStandard", 'stainlessStandard', 'blackAllmoge', 'blackStandard', 'whiteStandard', 'dolda']);
    function handleClick(selectedAlias) {
        const selectedItem = doorHinges.find(({ alias }) => alias === selectedAlias);
        if (selectedItem)
            dispatch(setDoorHingesItem(selectedItem));
    }
    useEffect(() => {
        if (selectedHandle && selectedHandle.alias === "dhHopStockBlack" && avHinges.includes("blackAllmoge")) {
            const item = doorHinges.find(({ alias }) => alias === `blackAllmoge${selectedHinges.count}`);
            if (item?.alias) {
                dispatch(setDoorHingesItem(item));
            }
        }
        if (selectedHandle && selectedHandle.alias === "dhDenvBl" && avHinges.includes("blackStandard")) {
            const item = doorHinges.find(({ alias }) => alias === `blackZink${selectedHinges.count}`);
            if (item?.alias) {
                dispatch(setDoorHingesItem(item));
            }
        }
    }, [selectedHandle]);
    useEffect(() => {
        if (selectedDoorModel?.hinges?.types?.items) {
            const types: Array<string> = [];
            selectedDoorModel?.hinges?.types?.items.forEach((obj) => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (value) {
                        types.push(key);
                    }
                });
            });
            setAvHinges(types);
        }
    }, [selectedDoorModel]);
    useEffect(() => {
        if (!selectedHinges?.item) {
            const firstHinges = doorHinges.find(({ count }) => count === selectedHinges.count);
            if (firstHinges?.alias) {
                dispatch(setDoorHingesItem(firstHinges));
            }
        }
    }, [doorHinges])
    return <IconItemsSelectPalette
        onItemClick={(alias) => {
            handleClick(alias)
        }}
        tooltip={tooltips['doorHinges']}
        title={t("doorHinges.title")}
        selectedBlockTitle={t("doorHinges.selectedLabel")}
        selectedItem={selectedHinges?.item}
        selectedLabel={t(`doorHinges.${selectedHinges?.item?.label}`)}
        itemsList={doorHinges.filter(({ count }) => count === selectedHinges.count).filter(({ label }) => avHinges.includes(label))}
    />
}

export default HingesItemsSelector;
