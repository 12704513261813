import DoorOutsideColor from "./SelectedDoorColors/DoorOutsideColor";
import DoorsChoser from "./Doors";
import DoorColorInside from "./SelectedDoorColors/DoorColorInside";
import ExtraMetalBlackMenu from "../ExtraMetalBlack";

function DoorItems() {

    return <>
        <DoorsChoser />
        <DoorOutsideColor />
        <DoorColorInside />
        <ExtraMetalBlackMenu />
    </>
}

export default DoorItems;