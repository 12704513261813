import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { getHingesSide, getSelectedBg, getSelectedDoor, getSideglassList } from "../../../../features/widgetState/widgetSlice";
import { DoorHoleSize, SideglasItem } from "../../../../types";
import GlassLayer from "./Glass";
import SceneFigure from "./SceneFigure";
import DoorHandlesLayer from "./Handles";
import HingesLayer from './Hinges';
import MetalPlateLayer from "./MetalPlateLayer";
import ExtrasLayer from "./Extras";

interface DoorHoleProps {
    imgUrl?: string;
    leftHinges?: boolean;
}

function DoorHoleRight({ imgUrl, leftHinges }: DoorHoleProps) {
    return <>
        <defs>
            <pattern id="img-door" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform={`${leftHinges ? "translate(-93,0) scale(-1,1)" : "translate(0,0)"}`}>
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="img-door" x="701.92" y="194.15" style={{ fill: 'url(#img-door)' }} width="302.94" height="648.25" />
    </>
}

function DoorHoleLeft({ imgUrl }: DoorHoleProps) {
    return <>
        <defs>
            <pattern id="img-door" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform="translate(30,0) scale(-1,1)">
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="door-left" style={{ fill: 'url(#img-door)' }} x="824.89" y="194.15" width="302.94" height="648.25" />
    </>
}
function DoorHoleSd30Left({ imgUrl }: DoorHoleProps) {
    return <>
        <defs>
            <pattern id="img-door" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform="translate(-2,0) scale(-1,1)">
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="door-sd30-left" style={{ fill: 'url(#img-door)' }} x="794.22" y="194.17" width="302.94" height="648.25" />
    </>
}

function Sd40Right({ imgUrl }: DoorHoleProps) {
    return <>
        <defs>
            <pattern id="img-sideglass" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform="translate(0,0)">
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="side40" x="1004.86" y="194.15" style={{ fill: 'url(#img-sideglass)' }} width="122.95" height="655.62" />
    </>
}

function Sd40Left({ imgUrl }: DoorHoleProps) {
    return <>
        <defs>
            <pattern id="img-sideglass" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform="translate(30,0) scale(-1,1)">
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="side40-left" style={{ fill: 'url(#img-sideglass)' }} x="701.94" y="194.15" width="122.95" height="655.62" />
    </>
}

function Sd30Left({ imgUrl }: DoorHoleProps) {
    return <>
        <defs>
            <pattern id="img-sideglass-sd30" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform="translate(-1,0) scale(-1,1)">
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="side30-left" style={{ fill: 'url(#img-sideglass-sd30)' }} x="701.84" y="194.16" width="92.37" height="655.74" />
    </>
}

function DoorTop120Left({ imgUrl }: DoorHoleProps) {
    return <>
        <defs>
            <pattern id="img-tp120-left" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform="translate(0,0)">
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="top120-left" style={{ fill: 'url(#img-tp120-left)' }} x="701.84" y="101.87" width="395.32" height="92.29" fill="#fff" />
    </>
}
function DoorTop({ imgUrl }: DoorHoleProps) {
    return <>
        <defs>
            <pattern id="img-tp130" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform="translate(0,0)">
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="img-tp130" x="701.92" y="101.86" style={{ fill: 'url(#img-tp130)' }} width="425.89" height="92.29" />
    </>
}

function Threshold() {
    return <>
        <defs>
            <pattern id="treshhold" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform={"translate(0,0)"}>
                <image xlinkHref={`./images/treshold1300.png`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>

        <rect id="treshhold" style={{ fill: 'url(#treshhold)' }} x="701.39" y="838.07" width="303.63" height="12.87" />
    </>
}
function ThresholdLeft() {
    return <>
        <defs>
            <pattern id="treshhold" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform={"translate(32,0)"}>
                <image xlinkHref={`./images/treshold1300.png`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="treshhold-left" style={{ fill: 'url(#treshhold)' }} x="824.36" y="838.07" width="303.63" height="12.87" />
    </>
}
function Threshold30Left() {
    return <>
        <defs>
            <pattern id="treshhold" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform={"translate(32,0)"}>
                <image xlinkHref={`./images/treshold1300.png`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="treshhold-30-left" style={{ fill: 'url(#treshhold)' }} x="794.22" y="838.09" width="303.63" height="12.87" />
    </>
}

function TreshHoldLayer({ selectedBgAlias, hingesSide }) {
    const isLeft = hingesSide === 'left';
    switch (selectedBgAlias) {
        case DoorHoleSize.door30side:
        case DoorHoleSize.door30sideTop:
            return <>{isLeft ? <Threshold30Left /> : <Threshold />}</>
        case DoorHoleSize.door40side:
        case DoorHoleSize.door40sideTop:
            return <>{isLeft ? <ThresholdLeft /> : <Threshold />}</>
        default:
            return <Threshold />
    }
}
function BaseSVG() {
    const sideglassList = useAppSelector(getSideglassList);
    const hingesSide = useAppSelector(getHingesSide);
    const doorItem = useAppSelector(getSelectedDoor);
    const selectedBg = useAppSelector(getSelectedBg);
    const [sideglassItem, setSideglassItem] = useState<SideglasItem>();

    useEffect(() => {
        if (doorItem?.color) {
            const sdColor = sideglassList[doorItem.color];
            setSideglassItem(sdColor);
        }
    }, [doorItem]);

    const doorLayers = (alias) => {
        switch (alias) {
            case DoorHoleSize.doorTop:
                return <>
                    <DoorTop imgUrl={sideglassItem?.topglass.tp90} />
                    <DoorHoleRight leftHinges={hingesSide === "left"} imgUrl={doorItem.imgUrl} />
                </>
            case DoorHoleSize.door30side:
                return <>
                    {hingesSide === "left" ?
                        <><Sd30Left imgUrl={sideglassItem?.sideglass.sd30} /> <DoorHoleSd30Left imgUrl={doorItem.imgUrl} /></>
                        : <><Sd40Right imgUrl={sideglassItem?.sideglass.sd30} /> <DoorHoleRight imgUrl={doorItem.imgUrl} /></>}
                </>
                break;
            case DoorHoleSize.door40side:
                return <>
                    {hingesSide === "left" ?
                        <><Sd40Left imgUrl={sideglassItem?.sideglass.sd40} /> <DoorHoleLeft imgUrl={doorItem.imgUrl} /></>
                        : <><Sd40Right imgUrl={sideglassItem?.sideglass.sd40} /> <DoorHoleRight imgUrl={doorItem.imgUrl} /></>}
                </>
                break;
            case DoorHoleSize.door30sideTop:
                return <>
                    {hingesSide === "left" ?
                        <><DoorTop120Left imgUrl={sideglassItem?.topglass.tp120} /><Sd30Left imgUrl={sideglassItem?.sideglass.sd30} /> <DoorHoleSd30Left imgUrl={doorItem.imgUrl} /></>
                        : <><DoorTop imgUrl={sideglassItem?.topglass.tp120} /><Sd40Right imgUrl={sideglassItem?.sideglass.sd30} /> <DoorHoleRight imgUrl={doorItem.imgUrl} /></>}
                </>
                break;
            case DoorHoleSize.door40sideTop:
                return <>
                    <DoorTop imgUrl={sideglassItem?.topglass.tp130} />
                    {hingesSide === "left" ?
                        <><Sd40Left imgUrl={sideglassItem?.sideglass.sd40} /> <DoorHoleLeft imgUrl={doorItem.imgUrl} /></>
                        : <><Sd40Right imgUrl={sideglassItem?.sideglass.sd40} /> <DoorHoleRight imgUrl={doorItem.imgUrl} /></>}
                </>
                break;
            case DoorHoleSize.onlyDoor:
            default:
                return <DoorHoleRight leftHinges={hingesSide === "left"} imgUrl={doorItem.imgUrl} />
        }
    }

    return <svg viewBox="0 0 1800 1010" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <GlassLayer />
        <image width="1800" height="1010" xlinkHref={selectedBg?.url} />
        <SceneFigure />
        {
            doorLayers(selectedBg.alias)
        }
        {TreshHoldLayer({ selectedBgAlias: selectedBg.alias, hingesSide })}
        <ExtrasLayer />
        <DoorHandlesLayer />
        <MetalPlateLayer />
        <HingesLayer />
    </svg >
}

export default BaseSVG;