import React, { FC } from 'react';
import styles from './CookiesAgreements.module.scss';

const CookiesAgreements: FC = () => {
    return (
        <div className={styles.container}>
            <a
                href="https://www.diplomatdorrar.se/integritetspolicy/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
            >
                Diplomatdörrar och cookies
            </a>
        </div>
    );
};

export default CookiesAgreements;
