import { useTranslation } from "react-i18next";
import { MenuBlockTitle, MenuBlockWrapper } from "../../../../common";
import styles from './door-size.module.scss'
import { DoorSizes } from "../../../../../types";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getSelectedDoorModel, getSelectedDoorSize, getTooltips, selectDoorSize } from "../../../../../features/widgetState/widgetSlice";
import { useEffect } from "react";

function DoorSizeSelector() {
    const dispatch = useAppDispatch();
    const tooltips = useAppSelector(getTooltips);
    const selectedDoorSize = useAppSelector(getSelectedDoorSize);
    const doorModel = useAppSelector(getSelectedDoorModel);
    const { t } = useTranslation();
    const handleClick = (selected: string) => {
        if (selected === selectedDoorSize) { return }
        dispatch(selectDoorSize(selected));
    }

    useEffect(() => {
        if (doorModel?.defaults.doorSize) {
            const sizeAlias = doorModel?.defaults.doorSize.startsWith('s') ? doorModel?.defaults.doorSize : `s${doorModel?.defaults.doorSize}`
            dispatch(selectDoorSize(DoorSizes[sizeAlias]));
        }
    }, [doorModel]);

    return <MenuBlockWrapper>
        <MenuBlockTitle tooltipTitle={tooltips['doorSize']}>{t(`doors.sizeSelectorTitle`)}</MenuBlockTitle>
        <div><p>Storlek (i modulmått)</p></div>
        <div className={styles.itemsWrapper}>
            {Object.keys(DoorSizes).map(key => {
                return <div
                    onClick={() => handleClick(DoorSizes[key])}
                    className={`${selectedDoorSize === DoorSizes[key] ? styles.selected : ''}`}
                    key={key}>{DoorSizes[key]}</div>
            })}
        </div>
    </MenuBlockWrapper>
}

export default DoorSizeSelector;