export const ExtraBlackMetal = {
    molly_glas: {
        black: './images/extra/molly_glas/ExtraOptionBlack-min.png',
        metal: './images/extra/molly_glas/ExtraOptionMetal-min.png'
    },
    minnah_glas: {
        black: './images/extra/minnah_glas/ExtraOptionBlack-min.png',
        metal: './images/extra/minnah_glas/ExtraOptionMetal-min.png'
    },
    ronja_glas: {
        black: './images/extra/ronja_glas/ExtraOptionSvart-min.png',
        metal: './images/extra/ronja_glas/ExtraOptionMetal-min.png'
    },
    margareta_glas: {
        lightgrey: './images/extra/margareta_glas/extraoption-lightgrey.png',
        green: './images/extra/margareta_glas/extraoption-green.png',
        blue: './images/extra/margareta_glas/extraoption-blue.png',
        red: './images/extra/margareta_glas/extraoption-red.png'
    },
    louise_glas: {
        white: "./images/extra/louise_glas/ExtraOptionWhite.png",
        black: './images/extra/louise_glas/ExtraOptionBlack.png',
        grey: './images/extra/louise_glas/ExtraOptionGrey.png'
    }
}