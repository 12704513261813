import { useTranslation } from "react-i18next";
import { MenuBlockTitle, MenuBlockWrapper } from "../../../common";
import styles from './doors.module.scss'
import { getFilteredDoorModelsList, getIsGlass, getSelectedDoorModel, getTooltips, setIsGlass, setSelectedDoorModel } from "../../../../features/widgetState/widgetSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useEffect } from "react";
import { SingleItem } from "../../../common/IconItemsSelectPalette";
import preview from "../../../common/IconItemsSelectPalette/icon-palette.module.scss"

function DoorsChoser() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const tooltips = useAppSelector(getTooltips);
    const isGlassSelected = useAppSelector(getIsGlass);
    const doorModelsList = useAppSelector(getFilteredDoorModelsList);
    const selectedDoorModel = useAppSelector(getSelectedDoorModel);
    const handleClick = (selected) => {
        if (isGlassSelected === selected) { return }
        dispatch(setIsGlass(selected));
    }
    const selectDoodModel = (doorModel) => {
        dispatch(setSelectedDoorModel(doorModel));
    }

    useEffect(() => {
        dispatch(setSelectedDoorModel(doorModelsList[0]));
    }, [isGlassSelected]);
    return (
        <MenuBlockWrapper>
            <MenuBlockTitle tooltipTitle={tooltips['doorSelector']}>{t(`doors.title`)}</MenuBlockTitle>

            <div className={`${preview.itemsWrapper} ${preview.selectedItemWrapper}`}>
                <div className={preview.textHolder}>{t('doors.selectedDoor')}</div>
                {selectedDoorModel ?
                    <>
                        <SingleItem extraClass={preview.noHover} alias={selectedDoorModel.iconUrl} iconUrl={selectedDoorModel.iconUrl} />
                        <div className={preview.colorLabel}>{selectedDoorModel.label}</div>
                    </> : null}
            </div>

            <div className={styles.itemsWrapper}>

                <div onClick={() => {
                    handleClick(true);
                }}
                    className={`${isGlassSelected ? styles.selected : ''}`}>{t(`doors.withGlass`)}</div>
                <div onClick={() => {
                    handleClick(false);
                }} className={`${!isGlassSelected ? styles.selected : ''}`}>{t(`doors.noGlass`)}</div>
            </div>
            <div className={styles.doorItemsWrapper}>
                {doorModelsList.map((item) => {
                    return <div
                        className={`${styles.imgWrapper} ${selectedDoorModel?.model === item.model ? styles.selectedDoor : ''}`}
                        onClick={() => selectDoodModel(item)}
                        key={item.model}><img src={item.iconUrl} />
                    </div>
                })}
            </div>
        </MenuBlockWrapper>
    )
}

export default DoorsChoser;
