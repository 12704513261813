import { useTranslation } from "react-i18next";
import { MenuBlockTitle, MenuBlockWrapper } from "../../../common";
import styles from './inside.module.scss';
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getSelectedDoorModel, getSetSelectedInsideItem, getWidgetData, setSelectedInsideItem } from "../../../../features/widgetState/widgetSlice";
import { useEffect, useState } from "react";

const insideItems = ['smooth', 'decorativeTracery', 'decorGlued']
function InsideComponent() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { tooltips } = useAppSelector(getWidgetData);
    const selectedDoorModel = useAppSelector(getSelectedDoorModel);
    const selectedInsideItem = useAppSelector(getSetSelectedInsideItem);
    const [avInsideItems, setAvInsideItems] = useState(insideItems);
    useEffect(() => {
        if (selectedDoorModel?.hinges?.types?.items) {
            const types: Array<string> = [];
            selectedDoorModel?.inside?.items.forEach((obj) => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (value) {
                        types.push(key);
                    }
                });
            });
            setAvInsideItems(types);
            if (!types.includes(selectedInsideItem) && types.length) {
                dispatch(setSelectedInsideItem(types[0]))
            }
        }
    }, [selectedDoorModel]);
    function handleClick(alias) {
        if (alias !== selectedInsideItem) {
            dispatch(setSelectedInsideItem(alias))
        }
    }
    return (
        <MenuBlockWrapper>
            <MenuBlockTitle tooltipTitle={tooltips['insideDoor']} >{t(`inside.title`)}</MenuBlockTitle>
            <div className={`${styles.itemsWrapper} ${avInsideItems.length === 3 ? styles.length3 : ""}`}>
                {avInsideItems
                    .map((item, index) => {
                        return (
                            <div
                                onClick={() => handleClick(item)}
                                className={`${selectedInsideItem === item ? styles.selected : ''}`}
                                key={index}
                            >
                                {t(`inside.${item}`)}
                            </div>
                        );
                    })}
            </div>
        </MenuBlockWrapper>
    );
}

export default InsideComponent;