import React from 'react';

function OrientationIcon(props) {
    return (
        <svg
            version="1.0"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 700 700"
            xmlSpace="preserve"
            {...props}
        >
            <style type="text/css">
                {
                    `.st0{fill:#FFFFFF;}`
                }
            </style>
            <g>
                <path className="st0" d="M212.5,125h25c6.9,0,12.5-5.6,12.5-12.5s-5.6-12.5-12.5-12.5h-25c-6.9,0-12.5,5.6-12.5,12.5 S205.6,125,212.5,125z" />
                <path className="st0" d="M313,125c6.9,0,12.5-5.6,12.5-12.5S319.9,100,313,100h-0.2c-7,0-12.4,5.6-12.4,12.5S306.1,125,313,125z" />
                <path className="st0" d="M162.5,575h-50c-6.9,0-12.5,5.6-12.5,12.5s5.6,12.5,12.5,12.5h50c6.9,0,12.5-5.6,12.5-12.5 S169.4,575,162.5,575z" />
                <path className="st0" d="M350,587.5c0-6.9-5.6-12.5-12.5-12.5h-50c-6.9,0-12.5,5.6-12.5,12.5s5.6,12.5,12.5,12.5h50 C344.4,600,350,594.4,350,587.5z" />
                <path className="st0" d="M587.5,300H400V112.5C400,78,372,50,337.5,50h-225C78,50,50,78.1,50,112.5v475c0,34.4,28,62.5,62.5,62.5h175 h50h250c34.5,0,62.5-28.1,62.5-62.5v-225C650,328,622,300,587.5,300z M525,325v300H387.5c7.9-10.5,12.5-23.5,12.5-37.5V325H525z  M75,175h300v350H75V175z M112.5,75h225c20.7,0,37.5,16.8,37.5,37.5V150H75v-37.5C75,91.8,91.8,75,112.5,75z M287.5,625h-175 C91.8,625,75,608.2,75,587.5V550h300v37.5c0,20.7-16.8,37.5-37.5,37.5H287.5z M625,587.5c0,20.7-16.8,37.5-37.5,37.5H550V325h37.5 c20.7,0,37.5,16.8,37.5,37.5V587.5z" />
                <path className="st0" d="M587.5,500c6.9,0,12.5-5.6,12.5-12.5v-25c0-6.9-5.6-12.5-12.5-12.5s-12.5,5.6-12.5,12.5v25 C575,494.4,580.6,500,587.5,500z" />
                <path className="st0" d="M588,550h-0.2c-7,0-12.4,5.6-12.4,12.5S581.1,575,588,575s12.5-5.6,12.5-12.5S594.9,550,588,550z" />
                <path className="st0" d="M437.5,100c73.6,0,133.9,58.1,137.3,130.9c-7.4-7.2-16.8-16.2-28.6-27.5c-5-4.8-13-4.6-17.7,0.4 c-4.8,5-4.6,13,0.4,17.7c20.2,19.3,42.7,41.3,47.6,46.9c2.2,4.1,6.4,6.6,11,6.6c3.6,0,7-1.6,9.4-4.2l49.5-49.5 c4.9-4.9,4.9-12.8,0-17.7c-4.9-4.9-12.8-4.9-17.7,0l-28.8,28.8C597.2,145.1,525.4,75,437.5,75c-6.9,0-12.5,5.6-12.5,12.5 S430.6,100,437.5,100z" />
            </g>
        </svg>
    );
}

export default OrientationIcon;
