import { FC } from 'react';
import { MenuBlockTitle, MenuBlockWrapper } from "..";
import styles from './icon-palette.module.scss'
import { useTranslation } from 'react-i18next';
interface IconItemsSelectPaletteProps {
    title: string;
    tooltip?: string;
    selectedLabel: string;
    selectedBlockTitle: string;
    itemsList: Array<{
        alias: string;
        iconUrl: string;
    }>;
    selectedItem?: {
        alias: string;
        iconUrl: string;
    };
    onItemClick: (alias: string) => void;

}
interface SingleIconItemProps {
    iconUrl: string;
    isSelected?: boolean;
    alias: string;
    extraClass?: string;
    onClick?: (alias: string) => void
}

export function SingleItem({
    iconUrl,
    isSelected,
    alias,
    onClick,
    extraClass
}: SingleIconItemProps) {
    function handleClick() {
        if (typeof onClick === "function") {
            onClick(alias);
        }
    }
    return <div
        onClick={() => handleClick()}
        className={`${styles.paletteItem} ${isSelected ? styles.selected : ""} ${extraClass || ""}`}>
        <img src={iconUrl} />
    </div>
}

const IconItemsSelectPalette: FC<IconItemsSelectPaletteProps> = ({ tooltip, title, selectedLabel, selectedBlockTitle, itemsList, onItemClick, selectedItem }) => {
    const { t } = useTranslation()
    return <MenuBlockWrapper>
        <MenuBlockTitle tooltipTitle={tooltip}>{title}</MenuBlockTitle>
        <div className={`${styles.itemsWrapper} ${styles.selectedItemWrapper}`}>
            <div className={styles.textHolder}>{selectedBlockTitle}</div>
            {selectedItem ? <SingleItem extraClass={styles.noHover} alias={selectedItem.iconUrl} iconUrl={selectedItem.iconUrl} /> : null}
            {selectedLabel ? <div className={styles.colorLabel}>{selectedLabel}</div> : null}
        </div>
        <div className={styles.iconsWrapper}>
            {itemsList.length ?
                itemsList.map(({ iconUrl, alias }) => <SingleItem
                    key={alias}
                    isSelected={alias === selectedItem?.alias}
                    alias={alias}
                    onClick={onItemClick}
                    iconUrl={iconUrl}
                />)
                : null}
        </div>

    </MenuBlockWrapper>
}

export default IconItemsSelectPalette;