import React, { FC } from 'react';
import { ColorItem } from '../../../types';
import styles from './color-palette.module.scss'


interface ColorPaletteProps {
    selectedAlias: string | undefined;
    items: ColorItem[];
    onItemClick: (id: string) => void;
}

const ColorPalette: FC<ColorPaletteProps> = ({ items, onItemClick, selectedAlias }) => {
    return (
        <div className={styles.wrapper}>
            {items.map((item) => (
                <div
                    className={`${styles.item} ${item.alias} ${selectedAlias === item.alias ? styles.selected : ""}`}
                    key={item.alias}
                    onClick={() => onItemClick(item.alias)}
                    style={{ backgroundColor: item.code }}
                >
                </div>
            ))}
        </div>
    );
};

export default ColorPalette;