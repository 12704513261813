import { useAppSelector } from '../../../../app/hooks';
import {
  getHingesSide,
  getSelectedBg,
  getSelectedDoorHinges,
} from '../../../../features/widgetState/widgetSlice';
import { DoorHoleSize } from '../../../../types';

interface DoorHingesProps {
  imgUrl?: string;
  leftHinges?: boolean;
}

function DoorHoleRight({ imgUrl, leftHinges }: DoorHingesProps) {
  return (
    <>
      <defs>
        <pattern
          id="img-hinges"
          patternUnits="userSpaceOnUse"
          width="1800"
          height="1010"
          patternTransform={`${
            leftHinges ? 'translate(-90,0) scale(-1,1)' : 'translate(0,0)'
          }`}
        >
          <image
            xlinkHref={`${imgUrl}`}
            x="0"
            y="0"
            width="1800"
            height="1010"
          />
        </pattern>
      </defs>
      <rect
        id="img-hinges-rect"
        x="701.92"
        y="194.15"
        style={{ fill: 'url(#img-hinges)' }}
        width="302.94"
        height="648.25"
      />
    </>
  );
}

function DoorHoleLeft({ imgUrl }: DoorHingesProps) {
  return (
    <>
      <defs>
        <pattern
          id="img-hinges"
          patternUnits="userSpaceOnUse"
          width="1800"
          height="1010"
          patternTransform="translate(30,0) scale(-1,1)"
        >
          <image
            xlinkHref={`${imgUrl}`}
            x="0"
            y="0"
            width="1800"
            height="1010"
          />
        </pattern>
      </defs>
      <rect
        id="img-hinges-left"
        style={{ fill: 'url(#img-hinges)' }}
        x="829.341"
        y="198.759"
        width="296.85"
        height="645.578"
      />
      <rect
        id="img-hinges-left"
        style={{ fill: 'url(#img-hinges)' }}
        x="824.89"
        y="194.15"
        width="302.94"
        height="648.25"
      />
    </>
  );
}
function DoorHoleSd30Left({ imgUrl }: DoorHingesProps) {
  return (
    <>
      <defs>
        <pattern
          id="img-hinges"
          patternUnits="userSpaceOnUse"
          width="1800"
          height="1010"
          patternTransform="translate(-2,0) scale(-1,1)"
        >
          <image
            xlinkHref={`${imgUrl}`}
            x="0"
            y="0"
            width="1800"
            height="1010"
          />
        </pattern>
      </defs>
      <rect
        id="img-hinges-sd30-left"
        style={{ fill: 'url(#img-hinges)' }}
        x="794.22"
        y="194.17"
        width="302.94"
        height="648.25"
      />
    </>
  );
}
function HingesLayer() {
  const selectedBg = useAppSelector(getSelectedBg);
  const hingesSide = useAppSelector(getHingesSide);
  const selectedHinges = useAppSelector(getSelectedDoorHinges);
  function DoorHingesChoser(alias) {
    switch (alias) {
      case DoorHoleSize.door40side:
      case DoorHoleSize.door40sideTop:
        return (
          <>
            {hingesSide === 'left' ? (
              <DoorHoleLeft imgUrl={selectedHinges?.item?.img} />
            ) : (
              <DoorHoleRight imgUrl={selectedHinges?.item?.img} />
            )}
          </>
        );
      case DoorHoleSize.door30sideTop:
      case DoorHoleSize.door30side:
        return (
          <>
            {hingesSide === 'left' ? (
              <DoorHoleSd30Left imgUrl={selectedHinges?.item?.img} />
            ) : (
              <DoorHoleRight imgUrl={selectedHinges?.item?.img} />
            )}
          </>
        );
      default:
        return (
          <DoorHoleRight
            leftHinges={hingesSide === 'left'}
            imgUrl={selectedHinges?.item?.img}
          />
        );
    }
  }
  return (
    <>
      {selectedHinges?.item?.label !== 'dolda'
        ? DoorHingesChoser(selectedBg.alias)
        : null}
    </>
  );
}

export default HingesLayer;
