import { useAppSelector } from "../../../../app/hooks"
import { getHingesSide, getSelectedBg, getSelectedDoorHandle } from "../../../../features/widgetState/widgetSlice"
import { DoorHoleSize } from "../../../../types";

interface DoorHandleProps {
    imgUrl?: string;
    leftHinges?: boolean;
}

function DoorHoleRight({ imgUrl, leftHinges }: DoorHandleProps) {
    return <>
        <defs>
            <pattern id="img-handle" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform={`${leftHinges ? "translate(-90,0) scale(-1,1)" : "translate(0,0)"}`}>
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="door" x="701.92" y="194.15" style={{ fill: 'url(#img-handle)' }} width="302.94" height="648.25"/>
    </>
}

function DoorHoleLeft({ imgUrl }: DoorHandleProps) {
    return <>
        <defs>
            <pattern id="img-handle" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform="translate(30,0) scale(-1,1)">
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="img-handle-left" style={{ fill: 'url(#img-handle)' }} x="829.341" y="198.759" width="296.85" height="645.578" />
        <rect id="img-handle-left" style={{ fill: 'url(#img-handle)' }} x="824.89" y="194.15" width="302.94" height="648.25" />
    </>
}
function DoorHoleSd30Left({ imgUrl }: DoorHandleProps) {
    return <>
        <defs>
            <pattern id="img-handle" patternUnits="userSpaceOnUse" width="1800" height="1010"
                patternTransform="translate(-2,0) scale(-1,1)">
                <image xlinkHref={`${imgUrl}`} x="0" y="0" width="1800" height="1010" />
            </pattern>
        </defs>
        <rect id="img-handle-sd30-left" style={{ fill: 'url(#img-handle)' }} x="794.22" y="194.17" width="302.94" height="648.25" />
    </>
}


function DoorHandlesLayer() {
    const selectedBg = useAppSelector(getSelectedBg);
    const hignesSide = useAppSelector(getHingesSide);
    const selectedHandle = useAppSelector(getSelectedDoorHandle);
    function DoorHandleChoser(alias) {
        switch (alias) {
            case DoorHoleSize.door40side:
            case DoorHoleSize.door40sideTop:
                return <>{hignesSide === "left" ? <DoorHoleLeft imgUrl={selectedHandle.img} /> : <DoorHoleRight imgUrl={selectedHandle.img} />}</>
            case DoorHoleSize.door30sideTop:
            case DoorHoleSize.door30side:
                return <>{hignesSide === "left" ? <DoorHoleSd30Left imgUrl={selectedHandle.img} /> : <DoorHoleRight imgUrl={selectedHandle.img} />}</>
            default:
                return <DoorHoleRight leftHinges={hignesSide === "left"} imgUrl={selectedHandle.img} />
        }
    }
    return <>{DoorHandleChoser(selectedBg.alias)}</>
}

export default DoorHandlesLayer