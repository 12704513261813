import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translations
import enTranslation from "./en.json";
import swTranslation from "./sw.json";

// Configure i18next
i18n
    .use(initReactI18next)
    .init({
        fallbackLng: "sw",
        debug: true,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: enTranslation
            },
            sw: {
                translation: swTranslation
            }
        }
    });

export default i18n;