import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getSelectedDoorHandle, getWidgetData, setDoorHandleItem } from "../../../../features/widgetState/widgetSlice";
import IconItemsSelectPalette from "../../../common/IconItemsSelectPalette";



function DoorHandlesSelector() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { doorHandles, tooltips } = useAppSelector(getWidgetData);
    const selectedHandle = useAppSelector(getSelectedDoorHandle);

    function handleClick(selectedAlias) {
        const selectedItem = doorHandles.find(({ alias }) => alias === selectedAlias);
        if (selectedItem)
            dispatch(setDoorHandleItem(selectedItem));
    }

    return <IconItemsSelectPalette
        onItemClick={(alias) => {
            handleClick(alias)
        }}
        tooltip={tooltips['doorHandle']}
        title={t("doorHandles.title")}
        selectedBlockTitle={t("doorHandles.selectedLabel")}
        selectedItem={selectedHandle}
        selectedLabel={t(`doorHandles.${selectedHandle.label}`)} //
        itemsList={doorHandles}
    />
}

export default DoorHandlesSelector;
