import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getBgGlassItem, getIsGlass, getSelectedBg, getSelectedDoorModel, getTooltips, getWidgetData, setBgGlassItem } from "../../../../features/widgetState/widgetSlice";
import IconItemsSelectPalette from "../../../common/IconItemsSelectPalette";
import { useEffect, useState } from "react";



function GlassSelector() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const tooltips = useAppSelector(getTooltips);
    const isGlassSelected = useAppSelector(getIsGlass);
    const selectedBg = useAppSelector(getSelectedBg);
    const { glassList } = useAppSelector(getWidgetData);
    const selectedGlass = useAppSelector(getBgGlassItem);
    const selectedDoorModel = useAppSelector(getSelectedDoorModel);
    const [avGlasses, setAvGlasses] = useState(glassList);

    function handleClick(selectedAlias) {
        const selectedItem = glassList.find(({ alias }) => alias === selectedAlias);
        if (selectedItem)
            dispatch(setBgGlassItem(selectedItem));
    }

    useEffect(() => {
        if (selectedDoorModel?.glassOptions?.default) {
            const selectedItem = glassList.find(({ alias }) => alias === selectedDoorModel?.glassOptions?.default);
            if (selectedItem) {
                dispatch(setBgGlassItem(selectedItem));
            }
        }
        if (selectedDoorModel?.glassOptions?.items) {
            const types: Array<string> = [];
            selectedDoorModel?.glassOptions?.items.forEach((obj) => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (value) {
                        const selectedItem = glassList.find(({ alias }) => alias === key);
                        if (selectedItem?.alias) {
                            // @ts-ignore
                            types.push(selectedItem);
                        }

                    }
                });
            });
            // @ts-ignore
            setAvGlasses(types);
        }
    }, [selectedDoorModel]);

    return <>{!isGlassSelected && selectedBg.alias === "do" ? null : <IconItemsSelectPalette
        tooltip={tooltips['glassType']}
        selectedBlockTitle={t('glassTypes.selectedLabel')}
        onItemClick={(alias) => {
            handleClick(alias)
        }}
        selectedItem={selectedGlass}
        selectedLabel={t(`glassTypes.${selectedGlass.label}`)}
        itemsList={avGlasses}
        title={t("glassTypes.title")}
    />}</>
}

export default GlassSelector;