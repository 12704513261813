import { DoorColors } from '../../types';

export const doorModels = [
  {
    label: 'Afrodite Glas',
    model: 'afrodite_glas',
    isGlass: true,
    defaults: {
      colorAlias: 'whiteYellow',
      doorSize: 's1022',
    },
    glassOptions: {
      default: 'clear',
      items: [
        {
          clear: true,
        },
        {
          costwood: true,
        },
        {
          frosted: true,
        },
        {
          black: false,
        },
        {
          silver: false,
        },
        {
          smoked: false,
        },
      ],
    },
    metalPlate: {
      hasMetalPlate: true,
      sizes: {
        items: [
          {
            "190": false,
          },
          {
            "100": true,
          },
        ],
      },
      types: {
        items: [
          {
            stainless: true,
          },
          {
            black: true,
          },
          {
            alu: true,
          },
        ],
      },
    },
    hinges: {
      sizes: {
        default: 'three',
        items: [
          {
            three: true,
          },
          {
            four: false,
          },
        ],
      },
      types: {
        default: 'glossStandard',
        items: [
          {
            glossStandard: true,
          },
          {
            stainlessStandard: true,
          },
          {
            blackAllmoge: true,
          },
          {
            blackStandard: true,
          },
          {
            whiteStandard: true,
          },
          {
            dolda: true,
          },
        ],
      },
    },
    "inside": {
      "default": "smooth",
      "items": [
        {
          "smooth": true
        },
        {
          "decorativeTracery": true
        },
        {
          "decorGlued": false
        }
      ]
    },
    iconUrl:
      'https://finance-ui.s3.eu-central-1.amazonaws.com/configurator/doorIcons/afrodite_glas.png',
    items: [
      {
        color: DoorColors.whiteYellow.alias,
        label: 'Afrodite',
        imgUrl: './images/afrodite/drAfrGlWhYl-min.png',
      },
      {
        color: DoorColors.white.alias,
        label: 'Afrodite',
        imgUrl: './images/afrodite/drAfrGlWh-min.png',
      },
      {
        color: DoorColors.lightgrey.alias,
        label: 'Afrodite',
        imgUrl: './images/afrodite/drAfrGlLg-min.png',
      },
      {
        color: DoorColors.darkgrey.alias,
        label: 'Afrodite',
        imgUrl: './images/afrodite/drAfrGlDg-min.png',
      },
      {
        color: DoorColors.green.alias,
        label: 'Afrodite',
        imgUrl: './images/afrodite/drAfrGlGr-min.png',
      },
      {
        color: DoorColors.blue.alias,
        label: 'Afrodite',
        imgUrl: './images/afrodite/drAfrGlBl-min.png',
      },
      {
        color: DoorColors.red.alias,
        label: 'Afrodite',
        imgUrl: './images/afrodite/drAfrGlR-min.png',
      },
      {
        color: DoorColors.black.alias,
        label: 'Afrodite',
        imgUrl: './images/afrodite/drAfrGlBlk-min.png',
      },
    ],
  },
  {
    label: 'Alexandra Glas',
    model: 'alexandra_glas',
    isGlass: true,
    defaults: {
      colorAlias: 'whiteYellow',
      doorSize: 's1022',
    },
    glassOptions: {
      default: 'clear',
      items: [
        {
          clear: true,
        },
        {
          costwood: true,
        },
        {
          frosted: true,
        },
        {
          black: false,
        },
        {
          silver: false,
        },
        {
          smoked: false,
        },
      ],
    },
    metalPlate: {
      hasMetalPlate: true,
      sizes: {
        items: [
          {
            "190": false,
          },
          {
            "100": true,
          },
        ],
      },
      types: {
        items: [
          {
            stainless: true,
          },
          {
            black: true,
          },
          {
            alu: true,
          },
        ],
      },
    },
    hinges: {
      sizes: {
        default: 'three',
        items: [
          {
            three: true,
          },
          {
            four: false,
          },
        ],
      },
      types: {
        default: 'glossStandard',
        items: [
          {
            glossStandard: true,
          },
          {
            stainlessStandard: true,
          },
          {
            blackAllmoge: true,
          },
          {
            blackStandard: true,
          },
          {
            whiteStandard: true,
          },
          {
            dolda: true,
          },
        ],
      },
    },
    "inside": {
      "default": "smooth",
      "items": [
        {
          "smooth": true
        },
        {
          "decorativeTracery": true
        },
        {
          "decorGlued": false
        }
      ]
    },
    imgUrl: './images/drFelGr.png',
    iconUrl:
      'https://finance-ui.s3.eu-central-1.amazonaws.com/configurator/doorIcons/alexandra_glas.png',
    items: [
      {
        color: DoorColors.whiteYellow.alias,
        label: 'Alexandra',
        imgUrl: './images/alexandra/drAleGlWhy-min.png',
      },
      {
        color: DoorColors.white.alias,
        label: 'Alexandra',
        imgUrl: './images/alexandra/drAleGlWh-min.png',
      },
      {
        color: DoorColors.lightgrey.alias,
        label: 'Alexandra',
        imgUrl: './images/alexandra/drAleGlLg-min.png',
      },
      {
        color: DoorColors.darkgrey.alias,
        label: 'Alexandra',
        imgUrl: './images/alexandra/drAleGlDg-min.png',
      },
      {
        color: DoorColors.green.alias,
        label: 'Alexandra',
        imgUrl: './images/alexandra/drAleGlGr-min.png',
      },
      {
        color: DoorColors.blue.alias,
        label: 'Alexandra',
        imgUrl: './images/alexandra/drAleGlBl-min.png',
      },
      {
        color: DoorColors.red.alias,
        label: 'Alexandra',
        imgUrl: './images/alexandra/drAleGlR-min.png',
      },
      {
        color: DoorColors.black.alias,
        label: 'Alexandra',
        imgUrl: './images/alexandra/drAleGlBlk-min.png',
      },
    ],
  },
  {
    label: 'Alma Glas',
    model: 'alma_glas',
    isGlass: true,
    defaults: {
      colorAlias: 'whiteYellow',
      doorSize: 's1022',
    },
    "inside": {
      "default": "smooth",
      "items": [
        {
          "smooth": true
        },
        {
          "decorativeTracery": false
        },
        {
          "decorGlued": false
        }
      ]
    },
    glassOptions: {
      default: 'clear',
      items: [
        {
          clear: true,
        },
        {
          costwood: true,
        },
        {
          frosted: true,
        },
        {
          black: false,
        },
        {
          silver: false,
        },
        {
          smoked: false,
        },
      ],
    },
    metalPlate: {
      hasMetalPlate: true,
      sizes: {
        items: [
          {
            "190": false,
          },
          {
            "100": true,
          },
        ],
      },
      types: {
        items: [
          {
            stainless: true,
          },
          {
            black: true,
          },
          {
            alu: true,
          },
        ],
      },
    },
    hinges: {
      sizes: {
        default: 'four',
        items: [
          {
            three: false,
          },
          {
            four: true,
          },
        ],
      },
      types: {
        default: 'glossStandard',
        items: [
          {
            glossStandard: true,
          },
          {
            stainlessStandard: true,
          },
          {
            blackAllmoge: true,
          },
          {
            blackStandard: true,
          },
          {
            whiteStandard: true,
          },
          {
            dolda: true,
          },
        ],
      },
    },
    iconUrl:
      'https://finance-ui.s3.eu-central-1.amazonaws.com/configurator/doorIcons/alma_glas.png',
    items: [
      {
        color: DoorColors.whiteYellow.alias,
        imgUrl: './images/drAlmaGlas/drAlmGlWhYl.png',
      },
      {
        color: DoorColors.white.alias,
        imgUrl: './images/drAlmaGlas/drAlmGlWh.png',
      },
      {
        color: DoorColors.lightgrey.alias,
        imgUrl: './images/drAlmaGlas/drAlmGlLg.png',
      },
      {
        color: DoorColors.darkgrey.alias,
        imgUrl: './images/drAlmaGlas/drAlmGlDg.png',
      },
      {
        color: DoorColors.green.alias,
        imgUrl: './images/drAlmaGlas/drAlmGlGr.png',
      },
      {
        color: DoorColors.blue.alias,
        imgUrl: './images/drAlmaGlas/drAlmGlBl.png',
      },
      {
        color: DoorColors.red.alias,
        imgUrl: './images/drAlmaGlas/drAlmGlR.png',
      },
      {
        color: DoorColors.black.alias,
        imgUrl: './images/drAlmaGlas/drAlmGlBlk.png',
      },
    ],
  },
  {
    label: 'Angelica Glas',
    model: 'angelica_glas',
    isGlass: true,
    defaults: {
      colorAlias: 'whiteYellow',
      doorSize: 's1022',
    },
    "inside": {
      "default": "smooth",
      "items": [
        {
          "smooth": true
        },
        {
          "decorativeTracery": false
        },
        {
          "decorGlued": false
        }
      ]
    },
    glassOptions: {
      default: 'clear',
      items: [
        {
          clear: true,
        },
        {
          costwood: true,
        },
        {
          frosted: true,
        },
        {
          black: false,
        },
        {
          silver: false,
        },
        {
          smoked: false,
        },
      ],
    },
    metalPlate: {
      hasMetalPlate: true,
      sizes: {
        items: [
          {
            "190": false,
          },
          {
            "100": true,
          },
        ],
      },
      types: {
        items: [
          {
            stainless: true,
          },
          {
            black: true,
          },
          {
            alu: true,
          },
        ],
      },
    },
    hinges: {
      sizes: {
        default: 'three',
        items: [
          {
            three: true,
          },
          {
            four: false,
          },
        ],
      },
      types: {
        default: 'glossStandard',
        items: [
          {
            glossStandard: true,
          },
          {
            stainlessStandard: true,
          },
          {
            blackAllmoge: true,
          },
          {
            blackStandard: true,
          },
          {
            whiteStandard: true,
          },
          {
            dolda: true,
          },
        ],
      },
    },
    iconUrl:
      'https://finance-ui.s3.eu-central-1.amazonaws.com/configurator/doorIcons/angelica_glas.png',
    items: [
      {
        color: DoorColors.whiteYellow.alias,
        imgUrl: './images/drAngelicaGlas/drAngeGlWhYl-min.png',
      },
      {
        color: DoorColors.white.alias,
        imgUrl: './images/drAngelicaGlas/drAngeGlWh-min.png',
      },
      {
        color: DoorColors.lightgrey.alias,
        imgUrl: './images/drAngelicaGlas/drAngeGlWhLg-min.png',
      },
      {
        color: DoorColors.darkgrey.alias,
        imgUrl: './images/drAngelicaGlas/drAngeGlWhDg-min.png',
      },
      {
        color: DoorColors.green.alias,
        imgUrl: './images/drAngelicaGlas/drAngeGlWhGr-min.png',
      },
      {
        color: DoorColors.blue.alias,
        imgUrl: './images/drAngelicaGlas/drAngeGlWhBl-min.png',
      },
      {
        color: DoorColors.red.alias,
        imgUrl: './images/drAngelicaGlas/drAngeGlWhR-min.png',
      },
      {
        color: DoorColors.black.alias,
        imgUrl: './images/drAngelicaGlas/drAngeGlWhBlk-min.png',
      },
    ],
  },
  {
    label: 'Clara Glas',
    model: 'clara_glas',
    isGlass: true,
    defaults: {
      colorAlias: 'whiteYellow',
      doorSize: 's1022',
    },
    glassOptions: {
      default: 'clear',
      items: [
        {
          clear: true,
        },
        {
          costwood: true,
        },
        {
          frosted: true,
        },
        {
          black: false,
        },
        {
          silver: false,
        },
        {
          smoked: false,
        },
      ],
    },
    metalPlate: {
      hasMetalPlate: true,
      sizes: {
        items: [
          {
            "190": true,
          },
          {
            "100": false,
          },
        ],
      },
      types: {
        items: [
          {
            stainless: true,
          },
          {
            black: true,
          },
          {
            alu: true,
          },
        ],
      },
    },
    hinges: {
      sizes: {
        default: 'three',
        items: [
          {
            three: true,
          },
          {
            four: false,
          },
        ],
      },
      types: {
        default: 'glossStandard',
        items: [
          {
            glossStandard: true,
          },
          {
            stainlessStandard: true,
          },
          {
            blackAllmoge: true,
          },
          {
            blackStandard: true,
          },
          {
            whiteStandard: true,
          },
          {
            dolda: true,
          },
        ],
      },
    },
    iconUrl:
      'https://finance-ui.s3.eu-central-1.amazonaws.com/configurator/doorIcons/clara_glas.png',
    items: [
      {
        color: DoorColors.whiteYellow.alias,
        label: 'Alexandra',
        imgUrl: './images/drClaraGlas/drClGlWhYl.png',
      },
      {
        color: DoorColors.white.alias,
        label: 'Alexandra',
        imgUrl: './images/drClaraGlas/drClGlWh.png',
      },
      {
        color: DoorColors.lightgrey.alias,
        label: 'Alexandra',
        imgUrl: './images/drClaraGlas/drClGlLg.png',
      },
      {
        color: DoorColors.darkgrey.alias,
        label: 'Alexandra',
        imgUrl: './images/drClaraGlas/drClGlDg.png',
      },
      {
        color: DoorColors.green.alias,
        label: 'Alexandra',
        imgUrl: './images/drClaraGlas/drClGlGr.png',
      },
      {
        color: DoorColors.blue.alias,
        label: 'Alexandra',
        imgUrl: './images/drClaraGlas/drClGlBl.png',
      },
      {
        color: DoorColors.red.alias,
        label: 'Alexandra',
        imgUrl: './images/drClaraGlas/drClGlR.png',
      },
      {
        color: DoorColors.black.alias,
        label: 'Alexandra',
        imgUrl: './images/drClaraGlas/drClGlBlk.png',
      },
    ],
  },
  {
    label: 'Anna Tat',
    model: 'anna_tat',
    isGlass: false,
    defaults: {
      colorAlias: 'whiteYellow',
      doorSize: 's1022',
    },
    glassOptions: {
      items: [
        {
          clear: false,
        },
        {
          costwood: false,
        },
        {
          frosted: false,
        },
        {
          black: false,
        },
        {
          silver: false,
        },
        {
          smoked: false,
        },
      ],
    },
    metalPlate: {
      hasMetalPlate: true,
      sizes: {
        items: [
          {
            "190": false,
          },
          {
            "100": true,
          },
        ],
      },
      types: {
        items: [
          {
            stainless: true,
          },
          {
            black: true,
          },
          {
            alu: true,
          },
        ],
      },
    },
    hinges: {
      sizes: {
        default: 'three',
        items: [
          {
            three: true,
          },
          {
            four: false,
          },
        ],
      },
      types: {
        default: 'glossStandard',
        items: [
          {
            glossStandard: true,
          },
          {
            stainlessStandard: true,
          },
          {
            blackAllmoge: true,
          },
          {
            blackStandard: true,
          },
          {
            whiteStandard: true,
          },
          {
            dolda: true,
          },
        ],
      },
    },
    iconUrl:
      'https://finance-ui.s3.eu-central-1.amazonaws.com/configurator/doorIcons/anna_tat.png',
    items: [
      {
        color: DoorColors.whiteYellow.alias,
        label: 'AnnaTät',
        imgUrl: './images/anna/drAnnTaWhYl-min.png',
      },
      {
        color: DoorColors.white.alias,
        label: 'AnnaTät',
        imgUrl: './images/anna/drAnnTaWh-min.png',
      },
      {
        color: DoorColors.lightgrey.alias,
        label: 'AnnaTät',
        imgUrl: './images/anna/drAnnTaLg-min.png',
      },
      {
        color: DoorColors.darkgrey.alias,
        label: 'AnnaTät',
        imgUrl: './images/anna/drAnnTaDg-min.png',
      },
      {
        color: DoorColors.green.alias,
        label: 'AnnaTät',
        imgUrl: './images/anna/drAnnTaGr-min.png',
      },
      {
        color: DoorColors.blue.alias,
        label: 'AnnaTät',
        imgUrl: './images/anna/drAnnTaBl-min.png',
      },
      {
        color: DoorColors.red.alias,
        label: 'AnnaTät',
        imgUrl: './images/anna/drAnnTaR-min.png',
      },
      {
        color: DoorColors.black.alias,
        label: 'AnnaTät',
        imgUrl: './images/anna/drAnnTaBlk-min.png',
      },
    ],
  },
  {
    label: 'Anita Tat',
    model: 'anita_tat',
    isGlass: false,
    defaults: {
      colorAlias: 'whiteYellow',
      doorSize: 's1022',
    },
    glassOptions: {
      items: [
        {
          clear: false,
        },
        {
          costwood: false,
        },
        {
          frosted: false,
        },
        {
          black: false,
        },
        {
          silver: false,
        },
        {
          smoked: false,
        },
      ],
    },
    metalPlate: {
      hasMetalPlate: true,
      sizes: {
        items: [
          {
            "190": true,
          },
          {
            "100": false,
          },
        ],
      },
      types: {
        items: [
          {
            stainless: true,
          },
          {
            black: true,
          },
          {
            alu: true,
          },
        ],
      },
    },
    hinges: {
      sizes: {
        default: 'three',
        items: [
          {
            three: true,
          },
          {
            four: false,
          },
        ],
      },
      types: {
        default: 'glossStandard',
        items: [
          {
            glossStandard: true,
          },
          {
            stainlessStandard: true,
          },
          {
            blackAllmoge: true,
          },
          {
            blackStandard: true,
          },
          {
            whiteStandard: true,
          },
          {
            dolda: true,
          },
        ],
      },
    },
    iconUrl:
      'https://finance-ui.s3.eu-central-1.amazonaws.com/configurator/doorIcons/anita_tat.png',
    items: [
      {
        color: DoorColors.whiteYellow.alias,
        label: 'AnnaTät',
        imgUrl: './images/drAnitaTat/drAnitTaWhYl-min.png',
      },
      {
        color: DoorColors.white.alias,
        label: 'AnnaTät',
        imgUrl: './images/drAnitaTat/drAnitTaWh-min.png',
      },
      {
        color: DoorColors.lightgrey.alias,
        label: 'AnnaTät',
        imgUrl: './images/drAnitaTat/drAnitTaLg-min.png',
      },
      {
        color: DoorColors.darkgrey.alias,
        label: 'AnnaTät',
        imgUrl: './images/drAnitaTat/drAnitTaDg-min.png',
      },
      {
        color: DoorColors.green.alias,
        label: 'AnnaTät',
        imgUrl: './images/drAnitaTat/drAnitTaGr-min.png',
      },
      {
        color: DoorColors.blue.alias,
        label: 'AnnaTät',
        imgUrl: './images/drAnitaTat/drAnitTaBl-min.png',
      },
      {
        color: DoorColors.red.alias,
        label: 'AnnaTät',
        imgUrl: './images/drAnitaTat/drAnitTaR-min.png',
      },
      {
        color: DoorColors.black.alias,
        label: 'AnnaTät',
        imgUrl: './images/drAnitaTat/drAnitTaBlk-min.png',
      },
    ],
  },
  {
    label: 'Bella Tat',
    model: 'bella_tat',
    isGlass: false,
    defaults: {
      colorAlias: 'whiteYellow',
      doorSize: 's1022',
    },
    glassOptions: {
      items: [
        {
          clear: false,
        },
        {
          costwood: false,
        },
        {
          frosted: false,
        },
        {
          black: false,
        },
        {
          silver: false,
        },
        {
          smoked: false,
        },
      ],
    },
    metalPlate: {
      hasMetalPlate: true,
      sizes: {
        items: [
          {
            "190": true,
          },
          {
            "100": false,
          },
        ],
      },
      types: {
        items: [
          {
            stainless: true,
          },
          {
            black: true,
          },
          {
            alu: true,
          },
        ],
      },
    },
    hinges: {
      sizes: {
        default: 'three',
        items: [
          {
            three: true,
          },
          {
            four: false,
          },
        ],
      },
      types: {
        default: 'glossStandard',
        items: [
          {
            glossStandard: true,
          },
          {
            stainlessStandard: true,
          },
          {
            blackAllmoge: true,
          },
          {
            blackStandard: true,
          },
          {
            whiteStandard: true,
          },
          {
            dolda: true,
          },
        ],
      },
    },
    iconUrl:
      'https://finance-ui.s3.eu-central-1.amazonaws.com/configurator/doorIcons/bella_tat.png',
    items: [
      {
        color: DoorColors.whiteYellow.alias,
        label: 'AnnaTät',
        imgUrl: './images/drBellaTat/drBelTatWhYl-min.png',
      },
      {
        color: DoorColors.white.alias,
        label: 'AnnaTät',
        imgUrl: './images/drBellaTat/drBelTatWh-min.png',
      },
      {
        color: DoorColors.lightgrey.alias,
        label: 'AnnaTät',
        imgUrl: './images/drBellaTat/drBelTatLg-min.png',
      },
      {
        color: DoorColors.darkgrey.alias,
        label: 'AnnaTät',
        imgUrl: './images/drBellaTat/drBelTatDg-min.png',
      },
      {
        color: DoorColors.green.alias,
        label: 'AnnaTät',
        imgUrl: './images/drBellaTat/drBelTatGr-min.png',
      },
      {
        color: DoorColors.blue.alias,
        label: 'AnnaTät',
        imgUrl: './images/drBellaTat/drBelTatBl-min.png',
      },
      {
        color: DoorColors.red.alias,
        label: 'AnnaTät',
        imgUrl: './images/drBellaTat/drBelTatR-min.png',
      },
      {
        color: DoorColors.black.alias,
        label: 'AnnaTät',
        imgUrl: './images/drBellaTat/drBelTatBlk-min.png',
      },
    ],
  },
  {
    label: 'Clara Tat',
    model: 'clara_tat',
    isGlass: false,
    defaults: {
      colorAlias: 'whiteYellow',
      doorSize: 's1022',
    },
    glassOptions: {
      items: [
        {
          clear: false,
        },
        {
          costwood: false,
        },
        {
          frosted: false,
        },
        {
          black: false,
        },
        {
          silver: false,
        },
        {
          smoked: false,
        },
      ],
    },
    metalPlate: {
      hasMetalPlate: true,
      sizes: {
        items: [
          {
            "190": true,
          },
          {
            "100": false,
          },
        ],
      },
      types: {
        items: [
          {
            stainless: true,
          },
          {
            black: true,
          },
          {
            alu: true,
          },
        ],
      },
    },
    hinges: {
      sizes: {
        default: 'three',
        items: [
          {
            three: true,
          },
          {
            four: false,
          },
        ],
      },
      types: {
        default: 'glossStandard',
        items: [
          {
            glossStandard: true,
          },
          {
            stainlessStandard: true,
          },
          {
            blackAllmoge: true,
          },
          {
            blackStandard: true,
          },
          {
            whiteStandard: true,
          },
          {
            dolda: true,
          },
        ],
      },
    },
    iconUrl:
      'https://finance-ui.s3.eu-central-1.amazonaws.com/configurator/doorIcons/clara_tat.png',
    items: [
      {
        color: DoorColors.whiteYellow.alias,
        label: 'AnnaTät',
        imgUrl: './images/drClaraTat/drClTaWhYl.png',
      },
      {
        color: DoorColors.white.alias,
        label: 'AnnaTät',
        imgUrl: './images/drClaraTat/drClTaWh.png',
      },
      {
        color: DoorColors.lightgrey.alias,
        label: 'AnnaTät',
        imgUrl: './images/drClaraTat/drClTaLg.png',
      },
      {
        color: DoorColors.darkgrey.alias,
        label: 'AnnaTät',
        imgUrl: './images/drClaraTat/drClTaDg.png',
      },
      {
        color: DoorColors.green.alias,
        label: 'AnnaTät',
        imgUrl: './images/drClaraTat/drClTaGr.png',
      },
      {
        color: DoorColors.blue.alias,
        label: 'AnnaTät',
        imgUrl: './images/drClaraTat/drClTaBl.png',
      },
      {
        color: DoorColors.red.alias,
        label: 'AnnaTät',
        imgUrl: './images/drClaraTat/drClTaR.png',
      },
      {
        color: DoorColors.black.alias,
        label: 'AnnaTät',
        imgUrl: './images/drClaraTat/drClTaBlk.png',
      },
    ],
  },
  {
    label: 'Nike Tat',
    model: 'nike_tat',
    isGlass: false,
    defaults: {
      colorAlias: 'whiteYellow',
      doorSize: 's1022',
    },
    glassOptions: {
      items: [
        {
          clear: false,
        },
        {
          costwood: false,
        },
        {
          frosted: false,
        },
        {
          black: false,
        },
        {
          silver: false,
        },
        {
          smoked: false,
        },
      ],
    },
    metalPlate: {
      hasMetalPlate: true,
      sizes: {
        items: [
          {
            "190": false,
          },
          {
            "100": true,
          },
        ],
      },
      types: {
        items: [
          {
            stainless: true,
          },
          {
            black: true,
          },
          {
            alu: true,
          },
        ],
      },
    },
    hinges: {
      sizes: {
        default: 'three',
        items: [
          {
            three: true,
          },
          {
            four: false,
          },
        ],
      },
      types: {
        default: 'glossStandard',
        items: [
          {
            glossStandard: true,
          },
          {
            stainlessStandard: true,
          },
          {
            blackAllmoge: true,
          },
          {
            blackStandard: true,
          },
          {
            whiteStandard: true,
          },
          {
            dolda: true,
          },
        ],
      },
    },
    iconUrl:
      'https://finance-ui.s3.eu-central-1.amazonaws.com/configurator/doorIcons/nike_tat.png',
    items: [
      {
        color: DoorColors.whiteYellow.alias,
        label: 'NikeTat',
        imgUrl: './images/drNikeTat/drNikTaWhYl.png',
      },
      {
        color: DoorColors.white.alias,
        label: 'AnnaTät',
        imgUrl: './images/drNikeTat/drNikTaWh.png',
      },
      {
        color: DoorColors.lightgrey.alias,
        label: 'AnnaTät',
        imgUrl: './images/drNikeTat/drNikTaLg.png',
      },
      {
        color: DoorColors.darkgrey.alias,
        label: 'AnnaTät',
        imgUrl: './images/drNikeTat/drNikTaDg.png',
      },
      {
        color: DoorColors.green.alias,
        label: 'AnnaTät',
        imgUrl: './images/drNikeTat/drNikTaGr.png',
      },
      {
        color: DoorColors.blue.alias,
        label: 'AnnaTät',
        imgUrl: './images/drNikeTat/drNikTaBl.png',
      },
      {
        color: DoorColors.red.alias,
        label: 'AnnaTät',
        imgUrl: './images/drNikeTat/drNikTaR.png',
      },
      {
        color: DoorColors.black.alias,
        label: 'AnnaTät',
        imgUrl: './images/drNikeTat/drNikTaBlk.png',
      },
    ],
  },
];
