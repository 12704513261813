import { BgColors, FacadeType, SceneType } from "../../types";

export const bgSc03 = [
    {
        scene: {
            type: SceneType["60s"]
        },
        facadeType: FacadeType.bricks,
        color: BgColors.brown,
        bg: {
            "do": {
                alias: 'do',
                url: './images/bg/sc03/sc03doBfbr.png'
            },
            "dt": {
                alias: 'dt',
                url: './images/bg/sc03/sc03doBfbr-dt.png'
            },
            "ds30": {
                alias: 'ds30',
                url: './images/bg/sc03/sc03doBfbr-ds30.png'
            },
            "ds30t": {
                alias: 'ds30t',
                url: './images/bg/sc03/sc03doBfbre-ds30t.png'
            },
            "ds40": {
                alias: 'ds40',
                url: './images/bg/sc03/sc03doBfbr-ds40.png'
            },
            "ds40t": {
                alias: 'ds40t',
                url: './images/bg/sc03/sc03doBfbre-ds40t.png'
            }
        }
    },
    {
        scene: {
            type: SceneType["60s"]
        },
        facadeType: FacadeType.bricks,
        color: BgColors.yellow,
        bg: {
            "do": {
                alias: 'do',
                url: './images/bg/sc03/sc03doBfye30.png'
            },
            "dt": {
                alias: 'dt',
                url: './images/bg/sc03/sc03doBfye-dt.png'
            },
            "ds30": {
                alias: 'ds30',
                url: './images/bg/sc03/sc03doBfye-ds30.png'
            },
            "ds30t": {
                alias: 'ds30t',
                url: './images/bg/sc03/sc03doBfye-ds30t.png'
            },
            "ds40": {
                alias: 'ds40',
                url: './images/bg/sc03/sc03doBfye-ds40.png'
            },
            "ds40t": {
                alias: 'ds40t',
                url: './images/bg/sc03/sc03doBfye-ds40t.png'
            }
        }
    },
    {
        scene: {
            type: SceneType["60s"]
        },
        facadeType: FacadeType.bricks,
        color: BgColors.red,
        bg: {
            "do": {
                alias: 'do',
                url: './images/bg/sc03/sc03doBfre.png'
            },
            "dt": {
                alias: 'dt',
                url: './images/bg/sc03/sc03doBfre-dt.png'
            },
            "ds30": {
                alias: 'ds30',
                url: './images/bg/sc03/sc03doBfre-ds30.png'
            },
            "ds30t": {
                alias: 'ds30t',
                url: './images/bg/sc03/sc03doBfre-ds30t.png'
            },
            "ds40": {
                alias: 'ds40',
                url: './images/bg/sc03/sc03doBfre-ds40.png'
            },
            "ds40t": {
                alias: 'ds40t',
                url: './images/bg/sc03/sc03doBfre-ds40t.png'
            }
        }
    },
    {
        scene: {
            type: SceneType["60s"]
        },
        facadeType: FacadeType.plaster,
        color: BgColors.white,
        bg: {
            "do": {
                alias: 'do',
                url: './images/bg/sc03/sc03doPfWh-ds.png'
            },
            "dt": {
                alias: 'dt',
                url: './images/bg/sc03/sc03doPfWh-dt.png'
            },
            "ds30": {
                alias: 'ds30',
                url: './images/bg/sc03/sc03doPfWh-30.png'
            },
            "ds30t": {
                alias: 'ds30t',
                url: './images/bg/sc03/sc03doPfWh-30t.png'
            },
            "ds40": {
                alias: 'ds40',
                url: './images/bg/sc03/sc03doPfWh-40.png'
            },
            "ds40t": {
                alias: 'ds40t',
                url: './images/bg/sc03/sc03doPfWh-40t.png'
            }
        }
    },
    {
        scene: {
            type: SceneType["60s"]
        },
        facadeType: FacadeType.plaster,
        color: BgColors.darkgrey,
        bg: {
            "do": {
                alias: 'do',
                url: './images/bg/sc03/sc03doPfGr-ds.png'
            },
            "dt": {
                alias: 'dt',
                url: './images/bg/sc03/sc03doPfGr-dt.png'
            },
            "ds30": {
                alias: 'ds30',
                url: './images/bg/sc03/sc03doPfGr-30.png'
            },
            "ds30t": {
                alias: 'ds30t',
                url: './images/bg/sc03/sc03doPfGr-30t.png'
            },
            "ds40": {
                alias: 'ds40',
                url: './images/bg/sc03/sc03doPfGr-40.png'
            },
            "ds40t": {
                alias: 'ds40t',
                url: './images/bg/sc03/sc03doPfGr-40t.png'
            }
        }
    },
    {
        scene: {
            type: SceneType["60s"]
        },
        facadeType: FacadeType.plaster,
        color: BgColors.yellow,
        bg: {
            "do": {
                alias: 'do',
                url: './images/bg/sc03/sc03doPfYb-ds.png'
            },
            "dt": {
                alias: 'dt',
                url: './images/bg/sc03/sc03doPfYb-dt.png'
            },
            "ds30": {
                alias: 'ds30',
                url: './images/bg/sc03/sc03doPfYb-30.png'
            },
            "ds30t": {
                alias: 'ds30t',
                url: './images/bg/sc03/sc03doPfYb-30t.png'
            },
            "ds40": {
                alias: 'ds40',
                url: './images/bg/sc03/sc03doPfYb-40.png'
            },
            "ds40t": {
                alias: 'ds40t',
                url: './images/bg/sc03/sc03doPfYb-40t.png'
            }
        }
    },
    {
        scene: {
            type: SceneType["60s"]
        },
        facadeType: FacadeType.plaster,
        color: BgColors.black,
        bg: {
            "do": {
                alias: 'do',
                url: './images/bg/sc03/sc03doPfBl-ds.png'
            },
            "dt": {
                alias: 'dt',
                url: './images/bg/sc03/sc03doPfBl-dt.png'
            },
            "ds30": {
                alias: 'ds30',
                url: './images/bg/sc03/sc03doPfBl-30.png'
            },
            "ds30t": {
                alias: 'ds30t',
                url: './images/bg/sc03/sc03doPfBl-30t.png'
            },
            "ds40": {
                alias: 'ds40',
                url: './images/bg/sc03/sc03doPfBl-40.png'
            },
            "ds40t": {
                alias: 'ds40t',
                url: './images/bg/sc03/sc03doPfBl-40t.png'
            }
        }
    },
]