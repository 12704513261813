import React, { FC } from 'react';
import { ColorItem } from '../../../types';
import styles from './color-palette.module.scss'

interface SingleColorItemProps {
    colorItem: ColorItem
}

const SingleColorItem: FC<SingleColorItemProps> = ({ colorItem }) => {
    return <div
        className={`${styles.colorItem}`}
        key={colorItem.alias}
        style={{ backgroundColor: colorItem.code }}
    >
    </div>
}

export default SingleColorItem;