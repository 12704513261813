export const fetchInitialData = async () => {
  const basePath = process.env.REACT_APP_API_URL;
  const projectId = process.env.REACT_APP_PROJET_ID;
  const url = window.location.pathname;
  const urlParts = url.split('/');
  const id = urlParts[urlParts.length - 1];
  const response = await fetch(
    `${basePath}/project/public/${id || projectId}`
  );
  if (!response.ok) {
    throw new Error(`Failed to fetch data (${response.status})`);
  }
  const data = await response.json();
  return data;
};
