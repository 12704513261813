import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  BgImageItem,
  BgGroup,
  DoorItem,
  DoorModel,
  DoorSizes,
  ColorItem,
  BgColors,
  SideglasList,
  BgGlassItem,
  DoorHandle,
  DoorHinges,
  MetalPlateItem,
  DoorColors,
  extraMetalBlackColors,
} from '../../types';
import {
  bgGlass,
  doorHandles,
  scenesFigures,
  sideglassList,
  doorHinges,
  metalPlates,
} from './defaultState';
import { bgSc03 } from './bgSc03';
import { bgScene01 } from './bgSc01';
import { bgScene04 } from './bgSc04';
import { doorModels } from './doorModels';
import { ExtraBlackMetal } from './extras';

export interface WidgetState {
  widgetData: {
    sideglassList: SideglasList;
    bgList: Array<BgGroup>;
    scenes?: any;
    doorModels: Array<DoorModel>;
    glassList: Array<BgGlassItem>;
    scenesFigures: any;
    doorHandles: Array<DoorHandle>;
    doorHinges: Array<DoorHinges>;
    metalPlates: Array<MetalPlateItem>;
    tooltips: Object;
    extras: object;
  };
  selectedState: {
    insideItem: string;
    metalBlackColor?: object;
    isLoading: boolean;
    doorHandle: DoorHandle;
    doorHinges: {
      count: number;
      item?: DoorHinges;
    };
    metalPlate: {
      size: number;
      selected?: MetalPlateItem;
    };
    glassItem: BgGlassItem;
    doorColorInside: ColorItem;
    doorSize: string;
    hinges: string;
    facade: {
      color?: string;
      type?: string;
    };
    scene: {
      sceneType: string;
    };
    bg: BgImageItem;
    bgGroup?: BgGroup;
    isGlass: boolean;
    doorItem: DoorItem;
    doorModel?: DoorModel;
    doorLayer: {
      doorUrl: string;
      topGlass?: string;
      sideGlass?: string;
    };
    threshold: string
  };
}

const initialState: WidgetState = {
  widgetData: {
    sideglassList: sideglassList,
    bgList: [...bgScene01, ...bgScene04, ...bgSc03],
    // @ts-ignore
    doorModels: doorModels,
    glassList: bgGlass,
    scenesFigures,
    doorHandles,
    doorHinges,
    metalPlates,
    tooltips: {},
    extras: {
      blackMetal: ExtraBlackMetal
    }
  },
  selectedState: {
    insideItem: "smooth",
    metalBlackColor: extraMetalBlackColors['black'],
    threshold: "25",
    isLoading: false,
    doorHandle: doorHandles[0],
    doorHinges: {
      count: 3,
    },
    metalPlate: {
      size: 100,
    },
    glassItem: bgGlass[0],
    doorColorInside: DoorColors.whiteYellow,
    doorSize: DoorSizes.s921,
    isGlass: true,
    hinges: 'right',
    facade: {
      color: undefined,
      type: undefined,
    },
    scene: {
      sceneType: 'classic',
    },
    doorLayer: {
      doorUrl: './images/drFelGr.png',
      topGlass: undefined,
      sideGlass: undefined,
    },
    bgGroup: undefined,
    doorModel: undefined,
    doorItem: {
      color: 'whiteYellow',
      model: 'Felicia',
      label: 'Felicia',
      imgUrl: './images/drFelGr.png',
    },
    bg: {
      alias: 'do',
      url: './images/bg/sc01do.png',
    },
  },
};

export const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    setSelectedInsideItem: (state, action) => {
      state.selectedState.insideItem = action.payload;
    },
    setSelectedMetalBlackColor: (state, action) => {
      state.selectedState.metalBlackColor = action.payload;
    },
    selectBg: (state, action) => {
      const selectedBg = state.selectedState.bgGroup?.bg[action.payload];
      if (!selectedBg) {
        return;
      }
      state.selectedState.bg = selectedBg;
    },
    selectFacadeSceneType: (state, action) => {
      const { scene, facadeType } = action.payload;
      state.selectedState.scene.sceneType = scene;
      state.selectedState.facade.type = facadeType;
      const firstFacade = state.widgetData.bgList.find((item) => {
        // @ts-ignore
        return item.scene.type === scene && item.facadeType === facadeType;
      });
      if (!firstFacade) {
        return;
      }
      state.selectedState.facade.color = firstFacade.color.alias;
      state.selectedState.bgGroup = firstFacade;
      const bgKeys = Object.keys(firstFacade.bg);
      state.selectedState.bg = firstFacade.bg[bgKeys[0]];
    },
    selectFacadeColor: (state, action) => {
      const selectedFacadeType = state.selectedState.facade.type;
      const sceneType = state.selectedState.scene.sceneType;
      state.selectedState.facade.color = action.payload;
      const firstFacade = state.widgetData.bgList.find(
        ({ facadeType, scene, color }) => {
          return (
            selectedFacadeType === facadeType &&
            color.alias === action.payload &&
            scene.type === sceneType
          );
        }
      );
      if (!firstFacade) {
        return;
      }
      state.selectedState.bgGroup = firstFacade;
      const bgKeys = Object.keys(firstFacade.bg);
      state.selectedState.bg = firstFacade.bg[bgKeys[0]];
    },
    setHingesSide: (state, action) => {
      state.selectedState.hinges = action.payload;
    },
    setIsGlass: (state, action) => {
      state.selectedState.isGlass = action.payload;
    },
    setSelectedDoorModel: (state, action) => {
      state.selectedState.doorModel = action.payload;
      state.selectedState.metalPlate.selected = undefined;
      if (state.selectedState.doorModel?.items[0]) {
        const colored = state.selectedState.doorModel?.items.find(({ color }) => {
          return color === state.selectedState.doorItem.color;
        })
        if (colored) {
          state.selectedState.doorItem = colored;
        } else {
          state.selectedState.doorItem = state.selectedState.doorModel?.items[0];
        }
      }
      if (state.selectedState?.doorModel?.hinges) {
        const { hinges } = state.selectedState?.doorModel
        if (hinges?.sizes) {
          state.selectedState.doorHinges.count = hinges?.sizes?.default === "four" ? 4 : 3;
        }
        if (hinges?.types?.default) {
          const hingesItem = doorHinges.find(({ count, label }) => count === state.selectedState.doorHinges.count && label === hinges?.types?.default);
          if (hingesItem) {
            state.selectedState.doorHinges.item = hingesItem;
          }
        }
      }
      state.selectedState.metalBlackColor = extraMetalBlackColors['black'];
    },
    setSelectedDoorItem: (state, action) => {
      state.selectedState.doorItem = action.payload;
    },
    selectDoorSize: (state, action) => {
      state.selectedState.doorSize = action.payload;
    },
    setDoorColorIncide: (state, action) => {
      state.selectedState.doorColorInside = action.payload;
    },
    setBgGlassItem: (state, action) => {
      state.selectedState.glassItem = action.payload;
    },
    setDoorHandleItem: (state, action) => {
      state.selectedState.doorHandle = action.payload;
    },
    setDoorHingesCount: (state, action) => {
      state.selectedState.doorHinges.count = action.payload;
    },
    setDoorHingesItem: (state, action) => {
      state.selectedState.doorHinges.item = action.payload;
    },
    setMetalPlateSize: (state, action) => {
      state.selectedState.metalPlate.size = action.payload;
    },
    setMetalPlateItem: (state, action) => {
      state.selectedState.metalPlate.selected = action.payload;
    },

    mergeDoorsWidgetData: (state, action) => {
      state.widgetData.doorModels = [
        ...action.payload.doorModels,
      ];
      state.widgetData.tooltips = action.payload.tooltips;
    },
    setIsLoading: (state, action) => {
      state.selectedState.isLoading = action.payload;
    },
    setSelectedThreshold: (state, action) => {
      state.selectedState.threshold = action.payload;
    }
  },
});

export const {
  setSelectedInsideItem,
  setSelectedMetalBlackColor,
  selectFacadeSceneType,
  setSelectedThreshold,
  setIsLoading,
  mergeDoorsWidgetData,
  setMetalPlateSize,
  setMetalPlateItem,
  setDoorHingesCount,
  setDoorHingesItem,
  setDoorHandleItem,
  setBgGlassItem,
  setDoorColorIncide,
  setSelectedDoorItem,
  selectDoorSize,
  selectBg,
  setIsGlass,
  setHingesSide,
  // selectSceneType,
  // selectFacadeType,
  selectFacadeColor,
  setSelectedDoorModel,
} = widgetSlice.actions;


export const getSetSelectedInsideItem = ({ widget }: RootState) => widget.selectedState.insideItem;
export const getSelectedMetalBlackColor = ({ widget }: RootState) => widget.selectedState.metalBlackColor;
export const getSelectedThreshold = ({ widget }: RootState) => widget.selectedState.threshold
export const getTooltips = ({ widget }: RootState) =>
  widget.widgetData.tooltips;
export const getIsLoading = ({ widget }: RootState) =>
  widget.selectedState.isLoading;
export const getSelectedMetalPlateItem = ({ widget }: RootState) =>
  widget.selectedState.metalPlate.selected;
export const getSelectedMetalPlateSize = ({ widget }: RootState) =>
  widget.selectedState.metalPlate.size;
export const getSelectedDoorHandle = ({ widget }: RootState) =>
  widget.selectedState.doorHandle;
export const getSelectedDoorHinges = ({ widget }: RootState) =>
  widget.selectedState.doorHinges;
export const getBgGlassItem = ({ widget }: RootState) =>
  widget.selectedState.glassItem;
export const getSelectedDoorColorIncide = ({ widget }: RootState) =>
  widget.selectedState.doorColorInside;
export const getSideglassList = ({ widget }: RootState) =>
  widget.widgetData.sideglassList;
export const getSelectedBg = ({ widget }: RootState) => widget.selectedState.bg;
export const getSelectedSceneType = ({ widget }: RootState) =>
  widget.selectedState.scene.sceneType;
export const getSelectedDoor = ({ widget }: RootState) =>
  widget.selectedState.doorItem;
export const getSelectedDoorModel = ({ widget }: RootState) =>
  widget.selectedState.doorModel;
// export const getDoorLayer = ({ widget }: RootState) => widget.selectedState.doorLayer;
export const getWidgetData = ({ widget }: RootState) => widget.widgetData;
export const getSelectedState = ({ widget }: RootState) => widget.selectedState;
export const getDoorModelsList = ({ widget }: RootState) =>
  widget.widgetData.doorModels;
export const getFilteredDoorModelsList = ({ widget }: RootState) =>
  widget.widgetData.doorModels.filter(({ isGlass }) => {
    return isGlass === widget.selectedState.isGlass;
  });
export const getIsGlass = ({ widget }: RootState) =>
  widget.selectedState.isGlass;

export const getHingesSide = ({ widget }: RootState) =>
  widget.selectedState.hinges;
export const getSelectedDoorSize = ({ widget }: RootState) =>
  widget.selectedState.doorSize;
// Facade selectors
export const getSelectedFacadeType = ({ widget }: RootState) =>
  widget.selectedState.facade.type;
export const getSelectedFacadeColor = ({ widget }: RootState) =>
  widget.selectedState.facade.color;

export const selectDoorHoleSizes = ({ widget }: RootState) => {
  const { bgGroup } = widget.selectedState;
  if (bgGroup?.bg) {
    return Object.keys(bgGroup.bg);
  }
  return [];
};
export const getFacadeTypes = ({ widget }: RootState) => {
  const { bgList } = widget.widgetData;
  let { sceneType } = widget.selectedState.scene;
  const facadesList = new Set(
    bgList
      .map(({ facadeType }) => facadeType)
  );
  //@ts-ignore
  return [...facadesList];
};

export const getAvailableScenes = ({ widget }: RootState) => {
  const { bgList } = widget.widgetData;
  const scenesList = new Set(bgList.map(({ scene }) => scene.type));
  //@ts-ignore
  return [...scenesList];
};

export const getBgsForFacadeType = ({ widget }: RootState) => {
  const { bgList } = widget.widgetData;
  const selectedFacadeType = widget.selectedState.facade.type;
  return bgList.filter(
    ({ facadeType, scene }) =>
      facadeType === selectedFacadeType &&
      scene.type === widget.selectedState.scene.sceneType
  );
};

export default widgetSlice.reducer;
