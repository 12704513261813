import React, { ReactNode } from 'react';
import './menuBlock.scss'
interface MenuBlockWrapperProps {
    children: ReactNode;
}

function MenuBlockWrapper(props: MenuBlockWrapperProps) {
    return (
        <div className="itemMainWrapper">
            {props.children}
        </div>
    );
}

export default MenuBlockWrapper;