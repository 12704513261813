import React, { useEffect, useRef, useState } from 'react';
import styles from './canvas.module.scss';
import { useAppSelector } from '../../../app/hooks';
import { getBgGlassItem, getSelectedBg, getSelectedDoor, getSelectedDoorHandle, getSelectedDoorHinges, getSelectedDoorModel, getSelectedMetalBlackColor, getSelectedMetalPlateItem, getSideglassList, getWidgetData } from '../../../features/widgetState/widgetSlice';
import { ColorItem, DoorHoleSize, SideglasItem } from '../../../types';


async function asyncLoadImage(src): Promise<HTMLImageElement | undefined> {
    if (!src) {
        return;
    }
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = "anonymous";
        image.src = src;
        image.onload = () => resolve(image);
        image.onerror = reject;
    });
}
interface CanvasComponentProps {
    callback(imageUrl: string): void;
}

const canvasWidth = {
    do: 300,
    sd30: 395,
    sd40: 426
}
const canvasHeight = {
    do: 644,
    top: 743
}
const canvasParringTop = {
    [canvasHeight.do]: -191,
    [canvasHeight.top]: -100,
}

const CanvasComponent: React.FC<CanvasComponentProps> = ({ callback }) => {
    const selectedBg = useAppSelector(getSelectedBg);
    const sideglassList = useAppSelector(getSideglassList);
    const selectedBgGlass = useAppSelector(getBgGlassItem);
    const doorItem = useAppSelector(getSelectedDoor);
    const selectedHandle = useAppSelector(getSelectedDoorHandle);
    const selectedHinges = useAppSelector(getSelectedDoorHinges);
    const doorModel = useAppSelector(getSelectedDoorModel);
    const selectedMetalBlackColor = useAppSelector(getSelectedMetalBlackColor) as ColorItem;
    const { extras } = useAppSelector(getWidgetData);
    const selectedMp = useAppSelector(getSelectedMetalPlateItem);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [holeWidth, setHoleWidth] = useState(canvasWidth.do);
    const [holeHeight, setHoleHeight] = useState(canvasHeight.do);

    const [sideglassItem, setSideglassItem] = useState<SideglasItem>();

    useEffect(() => {
        if (doorItem?.color) {
            const sdColor = sideglassList[doorItem.color];
            setSideglassItem(sdColor);
        }
    }, [doorItem]);

    const saveCanvasImage = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            callback(canvas.toDataURL());
        }
    };
    useEffect(() => {
        if (!sideglassItem?.sideglass || !doorItem?.imgUrl || !selectedBgGlass?.img) {
            return;
        }

        function getExtraUrl() {
            if (selectedMetalBlackColor.alias === "transparent") {
                return "";
            }
            switch (doorModel?.model) {
                case "molly_glas":
                    return extras['blackMetal']['molly_glas'][selectedMetalBlackColor.alias];
                    break;
                case "minnah_glas":
                    return extras['blackMetal']['minnah_glas'][selectedMetalBlackColor.alias];
                    break;
                case "ronja_glas":
                    return extras['blackMetal']['ronja_glas'][selectedMetalBlackColor.alias];
                    break;
                case "margareta_glas":
                    return extras['blackMetal']['margareta_glas'][selectedMetalBlackColor.alias];
                    break;
                case "louise_glas":
                    return extras['blackMetal']['louise_glas'][selectedMetalBlackColor.alias];
                    break;
                default:
                    return '';
            }
        }

        switch (selectedBg.alias) {
            case DoorHoleSize.door30side:
                setHoleHeight(canvasHeight.do)
                setHoleWidth(canvasWidth.sd30)
                runCanvas({
                    extrasUrl: getExtraUrl(),
                    holeHeightSize: canvasHeight.do,
                    hinges: selectedHinges.item?.img,
                    handle: selectedHandle.img,
                    glassUrl: selectedBgGlass?.img,
                    doorUrl: doorItem.imgUrl,
                    sideglassUrl: sideglassItem?.sideglass.sd30,
                    metalPlateUrl: selectedMp?.img
                });
                return
            case DoorHoleSize.door30sideTop:
                setHoleHeight(canvasHeight.top)
                setHoleWidth(canvasWidth.sd30)
                runCanvas({
                    extrasUrl: getExtraUrl(),
                    holeHeightSize: canvasHeight.top,
                    topglassUrl: sideglassItem?.topglass.tp120,
                    hinges: selectedHinges.item?.img,
                    handle: selectedHandle.img,
                    glassUrl: selectedBgGlass?.img,
                    doorUrl: doorItem.imgUrl,
                    sideglassUrl: sideglassItem?.sideglass.sd30,
                    metalPlateUrl: selectedMp?.img
                });
                return
            case DoorHoleSize.door40side:
                setHoleHeight(canvasHeight.do)
                setHoleWidth(canvasWidth.sd40)
                runCanvas({
                    extrasUrl: getExtraUrl(),
                    holeHeightSize: canvasHeight.do,
                    hinges: selectedHinges.item?.img,
                    handle: selectedHandle.img,
                    glassUrl: selectedBgGlass?.img,
                    doorUrl: doorItem.imgUrl,
                    sideglassUrl: sideglassItem?.sideglass.sd40,
                    metalPlateUrl: selectedMp?.img
                });
                return
            case DoorHoleSize.door40sideTop:
                setHoleHeight(canvasHeight.top)
                setHoleWidth(canvasWidth.sd40)
                runCanvas({
                    extrasUrl: getExtraUrl(),
                    holeHeightSize: canvasHeight.top,
                    topglassUrl: sideglassItem?.topglass.tp130,
                    hinges: selectedHinges.item?.img,
                    handle: selectedHandle.img,
                    glassUrl: selectedBgGlass?.img,
                    doorUrl: doorItem.imgUrl,
                    sideglassUrl: sideglassItem?.sideglass.sd40,
                    metalPlateUrl: selectedMp?.img
                });
                return
            case DoorHoleSize.doorTop:
                setHoleHeight(canvasHeight.top)
                setHoleWidth(canvasWidth.do)
                runCanvas({
                    extrasUrl: getExtraUrl(),
                    topglassUrl: sideglassItem?.topglass.tp90,
                    holeHeightSize: canvasHeight.top,
                    hinges: selectedHinges.item?.img,
                    handle: selectedHandle.img,
                    glassUrl: selectedBgGlass?.img,
                    doorUrl: doorItem.imgUrl,
                    metalPlateUrl: selectedMp?.img
                });
                return;
            default:
                setHoleWidth(canvasWidth.do)
                setHoleHeight(canvasHeight.do)
                runCanvas({
                    extrasUrl: getExtraUrl(),
                    holeHeightSize: canvasHeight.do,
                    hinges: selectedHinges.item?.img,
                    handle: selectedHandle.img,
                    glassUrl: selectedBgGlass?.img,
                    doorUrl: doorItem.imgUrl,
                    metalPlateUrl: selectedMp?.img
                });
                return;

        }
        async function runCanvas({
            holeHeightSize,
            glassUrl,
            doorUrl,
            sideglassUrl,
            topglassUrl,
            handle,
            extrasUrl,
            metalPlateUrl,
            hinges }: {
                holeHeightSize: number,
                glassUrl: string;
                doorUrl: string;
                sideglassUrl?: string;
                topglassUrl?: string;
                handle: string;
                extrasUrl?: string;
                metalPlateUrl?: string;
                hinges: string | undefined;
            }) {
            const canvas = canvasRef.current;
            if (canvas !== null) {
                const context = canvas.getContext('2d');
                if (context !== null) {
                    const [glassImage, doorImage, sideglass, handles, hingesImg, topGlassImg, extrasImg, metalPlateImg] = await Promise.all([
                        asyncLoadImage(glassUrl),
                        asyncLoadImage(doorUrl),
                        asyncLoadImage(sideglassUrl),
                        asyncLoadImage(handle),
                        asyncLoadImage(hinges),
                        asyncLoadImage(topglassUrl),
                        asyncLoadImage(extrasUrl),
                        asyncLoadImage(metalPlateUrl),
                    ]);
                    if (glassImage) {
                        context.drawImage(glassImage, -702, canvasParringTop[holeHeightSize], 1800, 1000);
                    }
                    if (doorImage) {
                        context.drawImage(doorImage, -702, canvasParringTop[holeHeightSize], 1800, 1000);
                    }
                    if (handles) {
                        context.drawImage(handles, -702, canvasParringTop[holeHeightSize], 1800, 1000);
                    }
                    if (hingesImg) {
                        context.drawImage(hingesImg, -702, canvasParringTop[holeHeightSize], 1800, 1000);
                    }
                    if (sideglass) {
                        context.drawImage(sideglass, -702, canvasParringTop[holeHeightSize], 1800, 1000);
                    }
                    if (topGlassImg) {
                        context.drawImage(topGlassImg, -702, canvasParringTop[holeHeightSize], 1800, 1000);
                    }
                    if (extrasImg) {
                        context.drawImage(extrasImg, -702, canvasParringTop[holeHeightSize], 1800, 1000);
                    }
                    if (metalPlateImg) {
                        context.drawImage(metalPlateImg, -702, canvasParringTop[holeHeightSize], 1800, 1000);
                    }

                    saveCanvasImage()
                }
            }
        }
    }, [selectedMp, selectedMetalBlackColor, selectedHinges, selectedHandle, selectedBg, selectedBgGlass, doorItem, sideglassItem]);

    return <div className={styles.canvasWrapper}><canvas width={holeWidth} height={holeHeight} ref={canvasRef} /></div>;
};

export default CanvasComponent;
