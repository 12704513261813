import { useTranslation } from "react-i18next";
import { getSelectedDoorModel, getSelectedMetalBlackColor, getWidgetData, setSelectedMetalBlackColor } from "../../../../features/widgetState/widgetSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useEffect, useState } from "react";
import { ColorItem, extraGlassColors, extraMetalBlack, extraMetalBlackColors, louiseGlasColors } from "../../../../types";
import DoorColorCommon from "../Doors/SelectedDoorColors/DoorColorCommon";

function ExtraMetalBlackMenu() {
    const { t } = useTranslation();
    const metalBlackPalette = Object.keys(extraMetalBlackColors).map(key => {
        return extraMetalBlackColors[key];
    })
    const extraGlassPalette = Object.keys(extraGlassColors).map(key => {
        return extraGlassColors[key];
    })
    const louiseGlasPalette = Object.keys(louiseGlasColors).map(key => {
        return louiseGlasColors[key];
    })
    const [isExtra, setIsExtra] = useState(false);
    const [colorPalette, setColorPalette] = useState(metalBlackPalette);
    const dispatch = useAppDispatch();
    const doorModel = useAppSelector(getSelectedDoorModel);
    const selectedMetalBlackColor = useAppSelector(getSelectedMetalBlackColor) as ColorItem;

    useEffect(() => {
        if (extraMetalBlack.includes(`${doorModel?.model}`)) {
            setIsExtra(true);
        } else {
            setIsExtra(false);
        }
        if (doorModel?.model === 'margareta_glas') {
            setColorPalette(extraGlassPalette)
        } else if (doorModel?.model === 'louise_glas') {
            setColorPalette(louiseGlasPalette)
            dispatch(setSelectedMetalBlackColor(louiseGlasColors['white']));
        } else {
            setColorPalette(metalBlackPalette)
        }
    }, [doorModel]);
    function handleMetalBlackClick(alias) {
        if (alias == selectedMetalBlackColor?.alias) {
            return;
        }

        if (doorModel?.model === 'margareta_glas') {
            dispatch(setSelectedMetalBlackColor(extraGlassColors[alias]));
        } else if (doorModel?.model === 'louise_glas') {
            dispatch(setSelectedMetalBlackColor(louiseGlasColors[alias]));
        } else {
            dispatch(setSelectedMetalBlackColor(extraMetalBlackColors[alias]));
        }
    }
    return <>{isExtra ?
        <DoorColorCommon
            title={doorModel?.model === 'margareta_glas' ? t('extra.extraGlassRombeTitle') : t('extra.extraMetalBlackTitle')}
            colorItems={colorPalette}
            selectedLabel={doorModel?.model === 'margareta_glas' ? t('extra.glassRombeSelectedTitle') : t('extra.metalBlackSelectedTitle')}
            onItemClick={handleMetalBlackClick}
            selectedColor={selectedMetalBlackColor}
            selectedColorAlias={selectedMetalBlackColor?.alias}
        />
        : null}</>
}

export default ExtraMetalBlackMenu;