import React from 'react';
import OrientationIcon from './OrientationIcon';
import style from "./rotate-mobile.module.scss"

function RotateMobile() {
    return <div className={style.rotateWrapper}>
        <div className={style.topText}>
            <div>dörrväljaren</div>
            <div className={style.h1}>diplomatdörrar</div>
        </div>
        <OrientationIcon />
        <div className={style.bottomText}>Roteta mobilen till liggande läge för att börja konfigurera</div>
    </div>;
}

export default RotateMobile;