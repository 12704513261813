import { FC } from 'react';
import { ColorPalette, MenuBlockTitle, MenuBlockWrapper } from "../../../../../common";
import styles from './door-color.module.scss'
import SingleColorItem from "../../../../../common/ColorPalette/SingleColorItem";
import { ColorItem } from '../../../../../../types';

interface DoorOutsideColorProps {
    title: string;
    tooltip?: string;
    selectedLabel: string;
    selectedColorAlias: string;
    colorItems: Array<ColorItem>;
    selectedColor: ColorItem;
    onItemClick: (alias: string) => void;

}

const DoorOutsideColor: FC<DoorOutsideColorProps> = ({ tooltip, title, selectedLabel, selectedColor, colorItems, onItemClick, selectedColorAlias }) => {
    return <MenuBlockWrapper>
        <MenuBlockTitle tooltipTitle={tooltip}>{title}</MenuBlockTitle>
        <div className={styles.itemsWrapper}>
            <div className={styles.textHolder}>{selectedLabel}</div>
            <SingleColorItem colorItem={selectedColor} />
            {selectedColor?.label ? <div className={styles.colorLabel}>{selectedColor.label}</div> : null}
        </div>

        {colorItems.length ? <ColorPalette selectedAlias={selectedColorAlias} items={colorItems} onItemClick={onItemClick} /> : null}

    </MenuBlockWrapper>
}

export default DoorOutsideColor;