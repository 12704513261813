import { BgColors, FacadeType, SceneType } from '../../types';

export const bgScene04 = [
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.white,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dovfWh-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dovfWh-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dovfWh-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dovfWh-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dovfWh-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dovfWh-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.lightgrey,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dovfLg-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dovfLg-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dovfLg-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dovfLg-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dovfLg-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dovfLg-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.darkgrey,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dovfDg-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dovfDg-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dovfDg-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dovfDg-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dovfDg-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dovfDg-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.beige,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dovfBe-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dovfBe-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dovfBe-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dovfBe-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dovfBe-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dovfBe-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.brown,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dovfBr-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dovfBr-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dovfBr-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dovfBr-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dovfBr-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dovfBr-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.yellow,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dovfYe-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dovfYe-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dovfYe-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dovfYe-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dovfYe-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dovfYe-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.red,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dovfRe-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dovfRe-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dovfRe-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dovfRe-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dovfRe-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dovfRe-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.green,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dovfGr-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dovfGr-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dovfGr-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dovfGr-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dovfGr-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dovfGr-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.verticalWood,
    color: BgColors.black,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dovfBl-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dovfBl-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dovfBl-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dovfBl-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dovfBl-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dovfBl-ds40t.png',
      },
    },
  },
  //
  //
  // horizontal wood
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.white,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dohfWh-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dohfWh-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dohfWh-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dohfWh-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dohfWh-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dohfWh-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.lightgrey,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dohfLg-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dohfLg-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dohfLg-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dohfLg-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dohfLg-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dohfLg-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.darkgrey,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dohfDg-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dohfDg-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dohfDg-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dohfDg-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dohfDg-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dohfDg-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.beige,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dohfBe-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dohfBe-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dohfBe-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dohfBe-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dohfBe-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dohfBe-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.brown,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dohfBr-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dohfBr-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dohfBr-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dohfBr-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dohfBr-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dohfBr-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.yellow,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dohfYe-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dohfYe-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dohfYe-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dohfYe-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dohfYe-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dohfYe-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.red,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dohfRe-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dohfRe-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dohfRe-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dohfRe-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dohfRe-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dohfRe-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.green,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dohfGr-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dohfGr-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dohfGr-ds30.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dohfGr-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dohfGr-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dohfGr-ds40t.png',
      },
    },
  },
  {
    scene: {
      type: SceneType.classic,
    },
    facadeType: FacadeType.horizontalWood,
    color: BgColors.black,
    bg: {
      do: {
        alias: 'do',
        url: './images/bg/sc04/sc04dohfBl-ds.png',
      },
      dt: {
        alias: 'dt',
        url: './images/bg/sc04/sc04dohfBl-dt.png',
      },
      ds30: {
        alias: 'ds30',
        url: './images/bg/sc04/sc04dohfBl-ds30t.png',
      },
      ds30t: {
        alias: 'ds30t',
        url: './images/bg/sc04/sc04dohfBl-ds30t.png',
      },
      ds40: {
        alias: 'ds40',
        url: './images/bg/sc04/sc04dohfBl-ds40.png',
      },
      ds40t: {
        alias: 'ds40t',
        url: './images/bg/sc04/sc04dohfBl-ds40t.png',
      },
    },
  },
];
