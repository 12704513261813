import { useTranslation } from "react-i18next";
import { DoorColors } from "../../../../../../types";
import DoorColorCommon from "../DoorColorCommon";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { getSelectedDoor, getSelectedDoorColorIncide, getTooltips, setDoorColorIncide } from "../../../../../../features/widgetState/widgetSlice";
import { useEffect } from "react";

function DoorColorInside() {
    const { t } = useTranslation();
    const tooltips = useAppSelector(getTooltips);
    const dispatch = useAppDispatch()
    const doorSelected = useAppSelector(getSelectedDoor);
    const selectedDoorColor = useAppSelector(getSelectedDoorColorIncide);
    const colorPalette = Object.keys(DoorColors).map(key => {
        return DoorColors[key]
    });
    useEffect(() => {
        if (doorSelected.color !== selectedDoorColor.alias) {
            dispatch(setDoorColorIncide(DoorColors[doorSelected.color]))
        }
    }, [doorSelected]);
    function handleClick(alias) {
        dispatch(setDoorColorIncide(DoorColors[alias]))
    }

    return <DoorColorCommon
        onItemClick={handleClick}
        colorItems={colorPalette}
        tooltip={tooltips['doorColorIn']}
        title={t('doors.insideColorTitle')}
        selectedLabel={t('doors.selectedColor')}
        selectedColor={selectedDoorColor}
        selectedColorAlias={selectedDoorColor.alias}
    />
}

export default DoorColorInside;