import FacadeType from "./MenuItems/FacadeType";
import Backgrounds from "./MenuItems/Backgrounds";
import SceneType from "./MenuItems/Scenes";
import styles from './menu.module.scss'
import DoorsItems from "./MenuItems/Doors";
import GlassSelector from "./MenuItems/GlassSelector";
import DoorHandles from "./MenuItems/DoorHandles";
import HingesItemsSelector from './MenuItems/Hinges'
import MetalPlateMenuItem from "./MenuItems/MetalPlate";
import ThresholdMenu from "./MenuItems/Threshold";
import ToPdf from "./MenuItems/ExportPDF";
import DoorSizeSelector from "./MenuItems/Doors/DoorSize";
import FacadeVsScenes from "./MenuItems/FacadeVsScenes";
import InsideComponent from "./MenuItems/Inside";
import CookiesAgreements from "./MenuItems/CookiesAgreements";

function Menu() {

    return (<div className={styles.mainMenuWrapper}>
        {/* <SceneType /> */}
        {/* <FacadeType /> */}
        <FacadeVsScenes />
        <Backgrounds />
        <DoorsItems />
        <GlassSelector />
        <DoorHandles />
        <HingesItemsSelector />
        <InsideComponent />
        <MetalPlateMenuItem />
        <DoorSizeSelector />
        <ThresholdMenu />
        <ToPdf />
        <CookiesAgreements />
    </div>)
}

export default Menu;