import React, { useState, ReactNode } from 'react';
import './menuBlockTitle.scss'
import InfoIcon from './InfoIcon';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface MenuBlockTitleProps {
    tooltipTitle?: string;
    children: ReactNode;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: 14,
        padding: '15px',
        borderRadius: '0px'
    },
}));

function MenuBlockTitle(props: MenuBlockTitleProps) {
    const [open, setOpen] = useState(false);

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    return (
        <div className="blockTitle">
            {props.children}
            {props.tooltipTitle &&
                <StyledTooltip
                    placement="left"
                    open={open}
                    title={props.tooltipTitle}
                    disableFocusListener
                >
                    <div className='infoWrapper'>
                        <IconButton
                            onClick={open ? handleTooltipClose : handleTooltipOpen}
                            onMouseEnter={handleTooltipOpen}
                            onMouseLeave={handleTooltipClose}
                            sx={{ padding: '0px' }}
                        >
                            <InfoIcon />
                        </IconButton>
                    </div>
                </StyledTooltip>}
        </div>
    );
}

export default MenuBlockTitle;
