import { useTranslation } from "react-i18next";
import { Button } from '@mui/material';
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import { MenuBlockTitle, MenuBlockWrapper } from "../../../common";
import styles from "./prf.module.scss"
import { useAppSelector } from "../../../../app/hooks";
import { getBgGlassItem, getHingesSide, getSelectedBg, getSelectedDoor, getSelectedDoorColorIncide, getSelectedDoorHandle, getSelectedDoorHinges, getSelectedDoorModel, getSelectedDoorSize, getSelectedMetalBlackColor, getSelectedMetalPlateItem, getSelectedThreshold, getSetSelectedInsideItem, getTooltips } from "../../../../features/widgetState/widgetSlice";
import PdfDocument from './PdfDocument';
import { ColorItem, DoorColors, extraMetalBlack } from "../../../../types";
import CanvasComponent from "../../../common/Canvas";
import { useState } from "react";


function ToPdf() {
    const { t } = useTranslation();
    const tooltips = useAppSelector(getTooltips);
    const doorModel = useAppSelector(getSelectedDoorModel);
    const selectedDoorSize = useAppSelector(getSelectedDoorSize);
    const selectedGlass = useAppSelector(getBgGlassItem);
    const doorSelected = useAppSelector(getSelectedDoor)
    const colorInside = useAppSelector(getSelectedDoorColorIncide);
    const hingesSide = useAppSelector(getHingesSide);
    const selectedBg = useAppSelector(getSelectedBg);
    const selectedHandle = useAppSelector(getSelectedDoorHandle);
    const selectedHinges = useAppSelector(getSelectedDoorHinges);
    const selectedMp = useAppSelector(getSelectedMetalPlateItem);
    const threshold = useAppSelector(getSelectedThreshold)
    const selectedMetalBlackColor = useAppSelector(getSelectedMetalBlackColor) as ColorItem;
    const selectedInsideItem = useAppSelector(getSetSelectedInsideItem);
    const openPdfInNewTab = async () => {
        const blob = await pdf(<PdfDocument
            doorModel={doorModel?.label}
            doorSize={selectedDoorSize}
            doorColorOut={DoorColors[doorSelected.color]?.label}
            doorColorInside={colorInside.label}
            hingesSide={hingesSide === "right" ? `${t(`doorOptions.hingesLeft`)}` : `${t(`doorOptions.hingesRight`)}`}
            doorHoleSize={`${t(`doorHoleSize.${selectedBg.alias}`)}`}
            doorHandle={`${t(`doorHandles.${selectedHandle.label}`)}`}
            doorHinges={`${t(`doorHinges.${selectedHinges?.item?.label}`)} x ${selectedHinges?.count}`}
            metalplate={!selectedMp?.alias ? `${t(`metalPlate.unselect`)}` : `${t(`metalPlate.${selectedMp.alias}`)} ${selectedMp.size}`}
            threshold={threshold === "25" ? `${t(`threshold.25`)}` : `${t(`threshold.35`)}`}
            //@ts-ignore
            extraTitle={extraMetalBlack.includes(`${doorModel?.model}`) ? (doorModel?.model === 'margareta_glas' ? t('extra.extraGlassRombeTitle') : t('extra.extraMetalBlackTitle')) : undefined}
            extraValue={selectedMetalBlackColor?.label}
            glass={`${t(`glassTypes.${selectedGlass.label}`)}`}
        />).toBlob();
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
    };
    const [doorImg, setDoorImg] = useState('');

    return (
        <MenuBlockWrapper>
            <MenuBlockTitle tooltipTitle={tooltips['toPdf']}>{t(`toPdf.title`)}</MenuBlockTitle>
            <div><CanvasComponent callback={(img) => {
                setDoorImg(img)
            }} /></div>
            <div>
                <p>Känner du dig nöjd med din design? Spara ned en specifikation och skicka den till valfri återförsäljare för en kostnadsfri offert. Du kan också välja att spara ned specifikationen som en PDF för att vid ett senare tillfälle ta med den till en återförsäljare.</p>
            </div>
            <div className={styles.itemsWrapper}>
                <PDFDownloadLink
                    document={<PdfDocument
                        doorModel={doorModel?.label}
                        doorSize={selectedDoorSize}
                        doorColorOut={DoorColors[doorSelected.color]?.label}
                        doorColorInside={colorInside.label}
                        hingesSide={hingesSide === "right" ? `${t(`doorOptions.hingesLeft`)}` : `${t(`doorOptions.hingesRight`)}`}
                        doorHoleSize={`${t(`doorHoleSize.${selectedBg.alias}`)}`}
                        doorHandle={`${t(`doorHandles.${selectedHandle.label}`)}`}
                        doorHinges={`${t(`doorHinges.${selectedHinges?.item?.label}`)} x ${selectedHinges?.item?.label === "dolda" ? 2 : selectedHinges?.count}`}
                        metalplate={!selectedMp?.alias ? `${t(`metalPlate.unselect`)}` : `${t(`metalPlate.${selectedMp.alias}`)} ${selectedMp.size}`}
                        threshold={threshold === "25" ? `${t(`threshold.25`)}` : `${t(`threshold.35`)}`}
                        //@ts-ignore
                        extraTitle={extraMetalBlack.includes(`${doorModel?.model}`) ? (doorModel?.model === 'margareta_glas' ? t('extra.extraGlassRombeTitle') : t('extra.extraMetalBlackTitle')) : undefined}
                        extraValue={selectedMetalBlackColor?.label}
                        glass={`${t(`glassTypes.${selectedGlass.label}`)}`}
                        insideItem={`${t(`inside.${selectedInsideItem}`)}`}
                        doorImage={doorImg}
                        hignesSide={hingesSide}
                    />}
                    fileName="konfigurerad_dorr.pdf"
                    style={{
                        textDecoration: "none",
                        padding: "0",
                        color: "#4a4a4a",
                        backgroundColor: "transparent",
                        border: "none",
                        width: "200px"
                    }}
                >
                    {({ blob, url, loading }) =>
                        loading ? 'Loading document...' : <Button style={{
                            backgroundColor: '#da1a30',
                            color: '#ffffff', // This is for white text color, adjust if necessary
                            border: 'none',
                            borderRadius: 0,
                            boxShadow: 'none'
                        }} variant="contained" color="secondary">{t(`toPdf.buttonTitle`)}</Button>
                    }
                </PDFDownloadLink>
            </div>
        </MenuBlockWrapper>
    );
}
export default ToPdf;
