import { useTranslation } from "react-i18next";
import { ColorItem, DoorColors } from "../../../../../../types";
import DoorColorCommon from '../DoorColorCommon'
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { getSelectedDoor, getSelectedDoorModel, getTooltips, setSelectedDoorItem } from "../../../../../../features/widgetState/widgetSlice";
import { useEffect, useState } from "react";

function DoorOutsideColor() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const tooltips = useAppSelector(getTooltips);
    const [colorItems, setColorItems] = useState<Array<any>>([])
    const [selectedColor, setSelectedColor] = useState<ColorItem>(DoorColors.white)
    const doorModelSelected = useAppSelector(getSelectedDoorModel)
    const doorSelected = useAppSelector(getSelectedDoor)
    const handleClick = (selected) => {
        const door = doorModelSelected?.items.find((item) => {
            return item.color === selected;
        })
        dispatch(setSelectedDoorItem(door));
    }
    useEffect(() => {
        if (doorSelected?.color) {
            setSelectedColor(DoorColors[doorSelected.color]);
        }
    }, [doorSelected]);
    useEffect(() => {
        if (!doorModelSelected) { return }
        const items = doorModelSelected.items.map(({ color }) => {
            return DoorColors[color];
        });
        if (items.length) {
            setColorItems(items);
        }
    }, [doorModelSelected]);
    return <DoorColorCommon
        tooltip={tooltips['doorColorOut']}
        onItemClick={handleClick}
        colorItems={colorItems}
        title={t('doors.outsideColorTitle')}
        selectedLabel={t('doors.selectedColor')}
        selectedColor={selectedColor}
        selectedColorAlias={doorSelected.color}
    />
}

export default DoorOutsideColor;