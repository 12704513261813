import { useTranslation } from 'react-i18next';
import styles from './facade-type.module.scss';
import {
  MenuBlockWrapper,
  MenuBlockTitle,
  ColorPalette,
} from '../../../common';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  getBgsForFacadeType,
  selectFacadeSceneType,
  getSelectedFacadeColor,
  getSelectedFacadeType,
  getSelectedSceneType,
  selectFacadeColor,
  getTooltips
} from '../../../../features/widgetState/widgetSlice';
import { ColorItem } from '../../../../types';
import { useEffect, useState } from 'react';

const facadesListFull = ["verticalWood_classic", 'verticalWood_modern', "horizontalWood_classic", 'horizontalWood_modern', "bricks_60s", "plaster_60s"]

function FacadeVsScenes() {
  const dispatch = useAppDispatch();
  const tooltips = useAppSelector(getTooltips);
  const selectedFacadeColor = useAppSelector(getSelectedFacadeColor);
  const selectedFacadeType = useAppSelector(getSelectedFacadeType);
  const bgsForFacadeType = useAppSelector(getBgsForFacadeType);
  const selectedScene = useAppSelector(getSelectedSceneType);
  const [facadeColors, setFacadeColors] = useState<Array<ColorItem>>([]);
  const { t } = useTranslation();
  function onClick(selected: string) {
    const facade = `${selected}`.split('_');

    dispatch(selectFacadeSceneType({ scene: facade[1], facadeType: facade[0] }));
  }
  useEffect(() => {
    const colors = [
      // @ts-ignore
      ...new Set(
        bgsForFacadeType.map(({ color }) => {
          return color;
        })
      ),
    ].map((item) => {
      return item;
    });
    setFacadeColors(colors);
  }, [selectedFacadeType, selectedScene]);

  function ColorPaletteOnclick(selected: string) {
    dispatch(selectFacadeColor(selected));
  }

  return (
    <MenuBlockWrapper>
      <MenuBlockTitle tooltipTitle={tooltips['facadeScene']}>{t(`facadeType.title`)}</MenuBlockTitle>
      <div className={styles.itemsWrapper}>
        {facadesListFull.map((type) => {
          return (
            <div
              className={`${`${selectedFacadeType}_${selectedScene}` === type ? styles.selected : ''
                }`}
              onClick={() => onClick(type)}
              key={type}
            >
              {t(`facadeType.${type}`)}
            </div>
          );
        })}
      </div>
      <MenuBlockTitle tooltipTitle={tooltips['facadeColor']}>{t(`facadeType.colorsTitle`)}</MenuBlockTitle>
      <ColorPalette
        selectedAlias={selectedFacadeColor}
        items={facadeColors}
        onItemClick={ColorPaletteOnclick}
      />
    </MenuBlockWrapper>
  );
}

export default FacadeVsScenes;
