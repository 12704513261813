import React from 'react';
import BaseSVG from './layers/svg-layout';

function WidgetBodyWrapper() {
  return (
    <>
      <BaseSVG />
    </>
  );
}

export default WidgetBodyWrapper;
